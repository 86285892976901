/**
 * Created by katat on 10/6/2017.
 */
module.exports = NotificationService;

NotificationService.$inject = ["ApiRequest"];

function NotificationService(ApiRequest) {
  _.extend(this, {
    saveNotification: saveNotification,
    getNotificationAll: getNotificationAll,
    getNotification: getNotification,
    getNotificationComments: getNotificationComments,
    saveNotificationComments: saveNotificationComments,
    updateNotification: updateNotification,
  });

  function saveNotification(notification) {
    const params = $.param({
      message: notification.message,
      host: notification.host,
      id_event: notification.id_event,
    });

    return ApiRequest.callApi("POST", "Notification/save/", params);
  }

  function getNotificationAll() {
    return ApiRequest.callApi("POST", "Notification/getall/", {});
  }

  function getNotification(id) {
    const params = {
      id: id,
    };

    return ApiRequest.callApi("POST", "Notification/get/", params);
  }

  function getNotificationComments(id) {
    const params = {
      id: id,
    };

    return ApiRequest.callApi("POST", "Notification/getcomments/", params);
  }

  function saveNotificationComments(comments) {
    const params = {
      id_notification: comments.id,
      comment: comments.comment,
    };

    return ApiRequest.callApi("POST", "Notification/savecomments/", params);
  }

  function updateNotification(id, status) {
    const params = {
      id: id,
      status: status,
    };

    return ApiRequest.callApi(
      "POST",
      "Notification/updatenotification/",
      params
    );
  }
}
