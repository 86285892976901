module.exports = NotificationDetailController;

NotificationDetailController.$inject = [
  "NotificationService",
  "UsersService",
  "$stateParams",
  "toaster",
];

function NotificationDetailController(
  NotificationService,
  UsersService,
  $stateParams,
  toaster
) {
  /************************************************
   * VARIABLES
   ************************************************/
  const vm = this;
  vm.comments = [];
  vm.host = {};
  vm.loading = false;
  vm.isCollapsed = true;
  vm.message = "";
  vm.displayError = false;

  /************************************************
   * METHODS
   ************************************************/
  vm.send = send;

  function init() {
    vm.loading = true;
    const id = $stateParams.id;

    NotificationService.getNotification(id)
      .then((result) => {
        if (result.data.can_read) {
          const idHost = result.data.notifications[0].host;
          UsersService.getUserInfobyId(idHost).then((hostResult) => {
            vm.host = hostResult.data.user;
          });
          getMessages();
        } else {
          vm.displayError = true;
        }
        vm.loading = false;
      })
      .catch(() => {
        vm.displayError = true;
        vm.loading = false;
      });
  }

  function getMessages() {
    const id = $stateParams.id;
    NotificationService.getNotificationComments(id).then((result) => {
      if (result.data.comments.length) {
        for (const comment of result.data.comments) {
          comment.date = moment
            .utc(comment.date)
            .local()
            .format("DD-MM-YYYY HH:mm");
        }
        vm.comments = result.data.comments;
      }
    });
  }

  function send() {
    if (!vm.message) return;

    const id = $stateParams.id;
    const message = { id, comment: vm.message };

    NotificationService.saveNotificationComments(message).then(
      () => {
        toaster.pop(
          "success",
          "Message sending",
          "Your message was sent.",
          3000
        );
        getMessages();
      },
      (err) => {
        toaster.pop(
          "error",
          "Error",
          "Something went wrong, please try again.",
          4000
        );
      }
    );

    vm.message = "";
  }

  init();
}
