module.exports = SearchController;

SearchController.$inject = [
  "$scope",
  "$rootScope",
  "$window",
  "$timeout",
  "$uibModal",
  "UsersService",
  "toaster",
  "$state",
  "CookieLawService",
  "StorageService",
];

function SearchController(
  $scope,
  $rootScope,
  $window,
  $timeout,
  $uibModal,
  UsersService,
  toaster,
  $state,
  CookieLawService,
  StorageService
) {
  /************************************************
   * VARIABLES
   ************************************************/
  const vm = this;
  vm.isHome = false;
  vm.isAuthenticated = false;
  vm.user = {};
  vm.isCollapsed = true;
  vm.searchobject = {};
  vm.date = "";
  vm.location = "";
  $scope.data = {};
  vm.language = "";
  vm.modalInstance = null;
  vm.whiteLogo = false;
  vm.selectedLanguage = null;

  const everywhere = angular.element(window.document);

  /************************************************
   * METHODS
   ************************************************/
  vm.signUp = signUp;
  vm.login = login;
  vm.logout = logout;
  vm.displayOffer = displayOffer;
  vm.noInternet = noInternet;

  everywhere.on("click", (event) => {
    const nav = document.getElementsByClassName("navbar-collapse")[0];
    const button = document.getElementsByClassName("navbar-toggle")[0];

    if (!nav.contains(event.target) && !button.contains(event.target)) {
      vm.isCollapsed = true;
    }

    $scope.$applyAsync();
  });

  $rootScope.online = navigator.onLine;

  const updateOnlineStatus = (status) => {
    $rootScope.$apply(() => {
      $rootScope.online = status;
    });
  };

  $window.addEventListener("offline", () => updateOnlineStatus(false), false);
  $window.addEventListener("online", () => updateOnlineStatus(true), false);

  if (!$rootScope.online) {
    $timeout(() => {
      angular.element("#nointernet").triggerHandler("click");
    });
  }

  /**
   * @name init
   * @description Initialize the controller
   */
  function init() {
    vm.isHome = window.location.href === baseURL;
    vm.isAuthenticated = Boolean($rootScope.currentUser);

    if (vm.isAuthenticated) {
      getUserInfo().catch((error) => {
        console.error("Failed to get user info:", error);
      });
    }

    vm.logoSrc = vm.isHome || vm.isAuthenticated ? "logo.svg" : "logo2.png";

    $scope.$on("cookieLaw.decline", () => {
      CookieLawService.decline();
      angular.element(".cl-banner").hide();
    });
  }

  function openModal(templateUrl) {
    return $uibModal.open({
      animation: true,
      templateUrl: templateUrl,
      controller: "ModalInstanceCtrl",
      scope: $scope,
      size: 1,
      resolve: {
        data: () => $scope.data,
      },
    });
  }

  function noInternet() {
    const modalInstance = openModal("assets/js/app/modal/noInternetModal.html");
    modalInstance.result
      .then(() => {})
      .catch(() => {
        modalInstance.close();
      });
  }

  function displayOffer() {
    openModal("assets/js/app/modal/premium-host.html");
  }

  /**
   *
   * @param index
   */
  function signUp(index, premium) {
    const lang = StorageService.getItem("lang");
    const modalInstance = $uibModal.open({
      animation: true,
      templateUrl: "assets/js/app/modal/sign-up.html",
      controller: "ModalInstanceCtrl",
      scope: $scope,
      size: 1,
      resolve: {
        data: () => ({
          ...$scope.data,
          premium,
          organizeFoodEvent: premium,
          lang,
        }),
      },
    });

    const handleSignUpSuccess = (response) => {
      if (response.data.response === "success") {
        toaster.pop(
          "success",
          "Account created",
          "Your account was created successfully, please check your email.",
          4000
        );
      } else if (response.data.response === "login") {
        setTimeout(() => {
          location.reload();
        }, 500);
      }
    };

    const handleSignUpError = () => {
      toaster.pop(
        "error",
        "Error",
        "Something went wrong, please try again.",
        4000
      );
    };

    modalInstance.result
      .then((data) => {
        let signUpMethod;
        if (data.cover_url) {
          signUpMethod = UsersService.signUpFacebook;
        } else if (data.Paa) {
          signUpMethod = UsersService.signUpGoogle;
        } else {
          signUpMethod = UsersService.signUp;
        }

        signUpMethod(data).then(handleSignUpSuccess, handleSignUpError);
      })
      .catch(() => {
        modalInstance.close();
      });
  }

  /**
   * @name login
   * @description Shows the login modal
   * @param index
   */
  function login(index) {
    const modalInstance = $uibModal.open({
      animation: true,
      templateUrl: "assets/js/app/modal/login.html",
      controller: "ModalInstanceCtrl",
      scope: $scope,
      size: 1,
      resolve: {
        data: () => $scope.data,
      },
    });

    const handleSuccess = (message) => {
      toaster.pop("success", "Password Reset", message, 4000);
    };

    const handleError = (message) => {
      toaster.pop("error", "Error", message, 4000);
    };

    const handleLoginSuccess = (response) => {
      const { token, user } = response.data;
      StorageService.setItem("token", token);
      StorageService.setItem(
        "showNewMessageNotification",
        user.unread_msg_cnt > 0
      );
      setTimeout(() => {
        const redirectUrl = localStorage.getItem("redirect");
        localStorage.removeItem("redirect");
        if (redirectUrl) window.location.href = redirectUrl;
        else location.reload();
      }, 500);
    };

    const handleLoginFailure = () => {
      toaster.pop(
        "warning",
        "Incorrect info",
        "Please check your email and password and try again.",
        4000
      );
    };

    modalInstance.result
      .then((data) => {
        if (data.recoveryEmail) {
          UsersService.sendResetToken(data.recoveryEmail).then(
            () =>
              handleSuccess(
                "Password reset instructions have been sent to your email."
              ),
            () =>
              handleError(
                "An error occurred while sending the reset instructions. Please try again."
              )
          );
        } else {
          UsersService.isVerified(data).then((response) => {
            const res = response.data.response;
            if (res === "confirmed") {
              let loginMethod = UsersService.login;
              if (data.cover_url) loginMethod = UsersService.signUpFacebook;
              else if (data.Paa) loginMethod = UsersService.signUpGoogle;

              loginMethod(data).then(
                (response) => {
                  const loginRes = response.data.response;
                  if (loginRes === "success" || loginRes === "login") {
                    handleLoginSuccess(response);
                  } else {
                    handleLoginFailure();
                  }
                },
                () =>
                  handleError(
                    "An error occurred during login. Please try again."
                  )
              );
            } else if (res === "no_confirmed") {
              handleError(
                "Your email is not verified. Please verify your email and try again."
              );
            } else {
              handleError("Your email is not registered. Please sign up.");
            }
          });
        }
      })
      .catch(() => {
        modalInstance.close();
      });
  }

  /**
   * @name logout
   * @description Destroys the user session
   * @returns {*}
   */
  function logout() {
    StorageService.deleteItem("token");
    window.location.href = baseURL;
  }

  /**
   * @name getUserInfo
   * @description Gets user info
   */
  function getUserInfo(forceRefresh) {
    return new Promise((resolve, reject) => {
      UsersService.getUserInfo(forceRefresh)
        .then((response) => {
          const { user } = response.data;
          vm.user = user;
          vm.user.image_path =
            user.image_path ||
            `${baseURL}assets/images/dashboard/no_avatar-xlarge.jpg`;
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  //Checking if current location is the homepage
  $rootScope.$on("$locationChangeStart", (event, next, current) => {
    vm.isAuthenticated = Boolean($rootScope.currentUser);
    vm.isCollapsed = true;

    if (vm.isAuthenticated) {
      getUserInfo(false)
        .then(() => {
          if (StorageService.getItem("showNewMessageNotification") === "true") {
            toaster.pop(
              "success",
              "Unread Message",
              "You have unread message(s).",
              4000
            );
            StorageService.setItem("showNewMessageNotification", false);
          }
        })
        .catch((error) => {
          console.error("Failed to get user info:", error);
        });
    }

    vm.isHome = baseURL === next.toString();
    vm.logoSrc = vm.isHome ? "logo.svg" : "logo2.png";
  });

  $rootScope.$watch(
    "currentUser",
    () => {
      vm.isAuthenticated = !!$rootScope.currentUser;
      vm.isCollapsed = true;

      if (vm.isAuthenticated) {
        getUserInfo(true)
          .then(handleUnreadMessages)
          .catch((error) => {
            console.error("Failed to get user info:", error);
          });
      }
    },
    true
  );

  const handleUnreadMessages = () => {
    if (StorageService.getItem("showNewMessageNotification") === "true") {
      toaster.pop(
        "success",
        "Unread Message",
        "You have unread message(s).",
        4000
      );
      StorageService.setItem("showNewMessageNotification", false);
    }
  };

  const handleEvent = (event, handler) => {
    $rootScope.$on(event, handler);
  };

  handleEvent("non-authenticate", () => vm.login(1));
  handleEvent("afterverification", () => vm.afterEmailVerificationLogin());
  handleEvent("linkSignUp", () => vm.signUp(1));
  handleEvent("linkPremiumSignUp", () => vm.signUp(1, 1));
  handleEvent("linkLogin", () => vm.login(1));
  $scope.$watch(
    function watch(scope) {
      return vm.searchobject;
    },
    function handle() {
      if (!vm.searchobject.lat || !vm.searchobject.lng) return;
      $state.go("search_hosts", {
        city: vm.location,
        lat: vm.searchobject.lat,
        lng: vm.searchobject.lng,
        distance: 50,
        date: null,
        cat: "",
      });
    },
    true
  );

  $rootScope.$watch(
    "globalSettings",
    () => {
      if ($rootScope.globalSettings) {
        vm.whiteLogo = Boolean($rootScope.globalSettings.whiteLogo);
      }
    },
    true
  );

  init();
}
