'use strict';

module.exports = FooterController;

FooterController.$inject = ['$scope', '$rootScope', 'UsersService', '$translate', 'StorageService', '$uibModal', 'toaster'];

function FooterController($scope, $rootScope, UsersService, $translate, StorageService, $uibModal, toaster) {

    /************************************************
     * VARIABLES
     ************************************************/
    var vm = this;
    vm.selectedLanguage = null;
    vm.currency = null;
    /************************************************
     * METHODS
     ************************************************/
    vm.languageChanged = languageChanged;
    vm.currencyChanged = currencyChanged;
    vm.getLanguage = getLanguage;
    vm.monitorUserActivity = monitorUserActivity;
    vm.showNewsletterSignup = showNewsletterSignup;


    function init() {
        var subdomain = window.location.hostname.split('.')[0];
        //Gets language from subdomain
        var lang = (subdomain === 'www' || subdomain === 'localhost' || subdomain === 'test') ? 'en' : subdomain;
        //Set language
        StorageService.deleteItem('lang');
        StorageService.setItem('lang', lang);
        vm.selectedLanguage = lang;
        //Sets language
        $translate.use(lang).then(function () {
            localStorage.setItem('lang', lang);
            $translate.refresh();
            $rootScope.$emit('languageChanged');
            moment.locale(lang);
        });
        var currency = StorageService.getItem('currency');
        if(!currency) {
            vm.currency = 'EUR';
            StorageService.setItem('currency', vm.currency);
            $rootScope.$emit('currencyChanged');
        } else {
            vm.currency = currency;
            $rootScope.$emit('currencyChanged');
        }
        window.setInterval(vm.monitorUserActivity, 2 * 60 * 1000);
    }

    /**
     * @name languageChanged
     * @description Sets language and redirects to subdomain
     * @param lang
     */
    function languageChanged(lang) {
        vm.selectedLanguage = lang;
        var subdomain = window.location.hostname.split('.')[0];
        if ((subdomain === 'www' || subdomain === 'localhost' || subdomain === 'test') && vm.selectedLanguage === 'en' || (subdomain === vm.selectedLanguage)) return;
        var currentLocation = window.location.href;
        var sub = vm.selectedLanguage === 'en' ? 'www' : vm.selectedLanguage;
        var newUrl = currentLocation.replace(subdomain, sub);
        window.location.href = newUrl;
    }

    function currencyChanged(){
        StorageService.deleteItem('currency');
        StorageService.setItem('currency', vm.currency);
        $rootScope.$emit('currencyChanged');
}

    function getLanguage() {
        var languageList = {
            'en' : 'language_english',
            'es' : 'language_spanish',
            'it' : 'language_italian',
            'fr' : 'language_french',
        };
        return languageList[vm.selectedLanguage];
    }

    // encourage user signup after 2 minutes activity
    function monitorUserActivity() {
        // we check every minute to see how he is doing
        var startTimestamp = Number(StorageService.getItem('startTimestamp'));
        var lastActivityTimestamp = Number(StorageService.getItem('lastActivity'));
        var currentTimestamp = Math.floor(Date.now() / 1000);
        StorageService.setItem('lastActivity', currentTimestamp);
        
        // When a user comes back later, we will ask again.
        if (!startTimestamp || (lastActivityTimestamp - startTimestamp) > 60 * 60) { 
            StorageService.setItem('startTimestamp', currentTimestamp);
            return;
        }

        if (!$rootScope.currentUser) { // No logged in user and not asked yet, then show the popup
            var alreadyAsked = StorageService.getItem('alreadyAsked');
            if (!alreadyAsked && (currentTimestamp - startTimestamp) > 2 * 60) {
                StorageService.setItem('alreadyAsked', true);

                showNewsletterSignup();
            }
        }
    }

    // newsletter signup modal, showing after 2 minutes activity
    function showNewsletterSignup() {
        if (document.querySelectorAll(".newsletter-modal").length > 0) return;
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'assets/js/app/modal/newsletterModal.html',
            controller: 'ModalInstanceCtrl',
            scope: $scope,
            size: 1,
            windowClass: "newsletter-modal",
            resolve: {
                data: function () {
                    return $scope.data;
                }.bind(this)
            }
        });
        modalInstance.result.then(function (data) {
            UsersService.newsletterSignup(data.email).then(
                function (response) {
                    if (response.data.response == "success")
                        toaster.pop("success", "Newsletter Signup", "You have successfuly signed up for our Newsletter.", 4000);
                    else
                        toaster.pop('error', "Error", "Something went wrong, please try again.", 4000);
                },
                function (err) {
                    toaster.pop('error', "Error", "Something went wrong, please try again.", 4000);
                }
            )
        }).catch(function (res) {
            modalInstance.close();
        });
    }

    
    init();
}