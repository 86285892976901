module.exports = DateFormat;

DateFormat.$inject = [];

function DateFormat() {

    /**
     *
     * @param {type} _date
     * @returns {moment}
     */
    return function (_date) {
        return !!_date ? moment(_date, 'YYYY-MM-DD').format('DD-MM-YYYY') : null;
    };
}