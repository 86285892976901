module.exports = ViewEventController;
ViewEventController.$inject = [
  "$stateParams",
  "toaster",
  "HostsService",
  "$state",
  "$rootScope",
  "$uibModal",
  "UsersService",
  "$scope",
  "NotificationService",
  "stripe",
  "$http",
  "$sce",
  "StorageService",
];

function ViewEventController(
  $stateParams,
  toaster,
  HostsService,
  $state,
  $rootScope,
  $uibModal,
  UsersService,
  $scope,
  NotificationService,
  stripe,
  $http,
  $sce,
  StorageService
) {
  const vm = this;
  vm.interval = 3000;
  let id = "";
  vm.details = {};
  vm.details.soldout = false;
  vm.details.hide_guest = false;
  vm.today = moment();
  vm.date = $stateParams.date
    ? moment($stateParams.date, "DD-MM-YYYY").toDate()
    : vm.today;
  vm.calendarMonth = $stateParams.date
    ? moment($stateParams.date, "DD-MM-YYYY").format("YYYY-MM-DD")
    : vm.today;
  vm.calendarMonth = moment(vm.calendarMonth);
  vm.isAuthenticated = false;
  vm.dateOptions = {
    minDate: new Date(),
  };
  vm.availableDates = [];
  vm.singleDate = new Date();
  vm.opened = false;
  vm.format = "dd-MM-yyyy";
  vm.selectedDates = [];
  vm.selectedDateFormated = null;
  vm.guests = 1;
  vm.checkEvent = false;
  vm.showPayment = false;
  vm.showSummary = false;
  vm.no_last_minute = false;
  vm.finalPrice = null;
  vm.card = {
    number: null,
    exp_month: null,
    exp_year: null,
    cvc: null,
  };
  vm.cardName = null;
  vm.duration = "";
  vm.guest = {};
  vm.isMobile = false;
  vm.showHostCardMobile = false;
  vm.notes = "";
  vm.phone = "";
  vm.country_code = "1";
  vm.checkGuest = false;
  vm.totalguests = [];
  vm.guestname = [];
  vm.guestemail = [];

  vm.isLoading = false;
  vm.selectedLanguage = "en";
  vm.currency = "";
  vm.eventLoaded = false;
  vm.originalFinalPrice = 0;
  //Coupons
  vm.displayDiscount = false;
  vm.discountCodeError = false;
  vm.discountCode = "";
  vm.discountApplied = false;
  vm.totalDiscount = 0;

  vm.no_avatar = `${baseURL}assets/images/dashboard/no_avatar-xlarge.jpg`;

  vm.goToUser = goToUser;
  vm.contactHost = contactHost;
  vm.login = login;
  vm.oneDaySelectionOnly = oneDaySelectionOnly;
  vm.openImage = openImage;
  vm.bookNow = bookNow;
  vm.displayEventInfo = displayEventInfo;
  vm.displayPayment = displayPayment;
  vm.goToMap = goToMap;
  vm.applyPayment = applyPayment;
  vm.applyFreeReservation = applyFreeReservation;
  vm.renderHtml = renderHtml;
  vm.getSlides = getSlides;
  vm.showPicker = showPicker;
  vm.getFeed = getFeed;
  vm.getSubtotal = getSubtotal;
  vm.getKidsSubtotal = getKidsSubtotal;
  vm.whoIsGoing = whoIsGoing;
  vm.displaySummary = displaySummary;
  vm.initShare = initShare;
  vm.changeGuest = changeGuest;
  vm.showNext = showNext;
  vm.showPrev = showPrev;
  vm.showDiscount = showDiscount;
  vm.useCode = useCode;
  vm.returnDiscount = returnDiscount;
  vm.showHostCard = showHostCard;
  vm.sanitizeHtml = sanitizeHtml;
  /**
   * @name useCode
   * @description Attempt to validate and apply a discount cuppon
   * @returns {undefined}
   */
  function useCode() {
    if (vm.discountCode === "") {
      vm.discountCodeError = true;
    } else {
      HostsService.validateCoupon(vm.discountCode).then(applyDiscount, error);
    }

    function applyDiscount(response) {
      const isValid = response.data.valid;
      if (isValid) {
        vm.discountCodeError = false;
        vm.discountApplied = true;
        vm.totalDiscount = response.data.value;
        setFinalPrice(vm.guests);
      } else {
        vm.discountCodeError = true;
      }
    }

    function error(err) {
      vm.discountCodeError = true;
      console.log(err);
    }
  }

  /**
   * @name showDiscount
   * @description Changes the value that displays the discont code field
   * @returns {undefined}
   */
  function showDiscount() {
    vm.displayDiscount = !vm.displayDiscount;
  }

  /**
   * @name showNext
   * @description calls the next slide
   * @returns {undefined}
   */
  function showNext() {
    const element = angular.element("#carousel-container .left");
    if (element.length > 0 && element.scope && element.scope().next) {
      element.scope().next();
    }
  }

  /**
   * @name showPrev
   * @description calls the prev slide
   * @returns {undefined}
   */
  function showPrev() {
    const element = angular.element("#carousel-container .right");
    if (element.length > 0 && element.scope && element.scope().prev) {
      element.scope().prev();
    }
  }

  /**
   * @name displayEventInfo
   * @returns {undefined}
   */
  function displayEventInfo() {
    vm.checkEvent = false;
    angular.element("html, body").animate({ scrollTop: 0 }, 600);
  }

  /**
   * @name displayPayment
   * @description displays the payment form
   * @returns {undefined}
   */
  function displayPayment() {
    vm.showPayment = true;
    angular.element("html, body").animate({ scrollTop: 0 }, 600);
  }

  function changeGuest() {
    if (vm.guests > 1) {
      vm.checkGuest = true;
      vm.totalguests = new Array(vm.guests - 1);
    } else {
      vm.checkGuest = false;
    }
  }

  function applyPayment() {
    vm.isLoading = true;

    const guestname = vm.guestname.length > 1 ? vm.guestname : null;
    const guestemail = vm.guestemail.length > 1 ? vm.guestemail : null;

    stripe.card
      .createToken(vm.card)
      .then((response) => {
        const token = StorageService.getItem("token") || "";
        const params = {
          access_token: response.id,
          amount: Math.round(vm.finalPrice),
          last_four: response.card.last4,
          title: vm.details.title,
          first_name: vm.guest.first_name,
          last_name: vm.guest.last_name,
          address: vm.guest.address,
          id_host: vm.details.id_user,
          id_guest: vm.guest.id,
          guests_qty: vm.guests,
          kids_qty: vm.kids,
          event_date: moment(vm.selectedDates[0]).format("YYYY-MM-DD"),
          event_id: vm.details.id,
          host_name: vm.details.first_name,
          host_email: vm.details.email,
          formated_date: vm.selectedDateFormated,
          price_person: vm.details.price,
          guest_email: vm.guest.email,
          currency: vm.currency,
          original_currency: vm.details.currency,
          original_price: vm.details.original_price,
          original_final_price: Math.round(vm.originalFinalPrice),
          notes: vm.notes,
          phone: `+${vm.country_code}${vm.phone}`,
          guestemail: guestemail,
          guestname: guestname,
          discount_code: vm.discountApplied ? vm.discountCode : "",
          token: token,
        };

        return $http({
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          url: `${baseURL}Stripe/Payment/process/`,
          method: "POST",
          data: $.param(params),
        });
      })
      .then((response) => {
        if (response.data.response === "success") {
          $state.go("view_itinerary", {
            token: response.data.token,
            phonenumber: vm.details.phone_number,
          });
        }
        vm.isLoading = false;
      })
      .catch((err) => {
        if (err.type && /^Stripe/.test(err.type)) {
          console.log("Stripe error: ", err.message);
        } else {
          console.log(
            "Other error occurred, possibly with your API",
            err.message
          );
        }
        vm.isLoading = false;
      });
  }

  function applyFreeReservation() {
    vm.isLoading = true;

    const event = {
      details: vm.details,
      guest: vm.guest,
      guests_qty: vm.guests,
      kids_qty: vm.kids,
      currency: vm.currency,
      formated_date: moment(vm.selectedDates[0]).format("LL"),
      event_date: moment(vm.selectedDates[0]).format("YYYY-MM-DD"),
      notes: vm.notes,
      phone: `${vm.country_code}${vm.phone}`,
      guestemail: vm.guestemail,
      guestname: vm.guestname,
    };

    HostsService.eventFreeReservation(event)
      .then((response) => {
        if (response.data.response === "success") {
          $state.go("view_itinerary", {
            token: response.data.token,
            phonenumber: vm.details.phone_number,
          });
        }
        vm.isLoading = false;
      })
      .catch((_err) => {
        toaster.pop(
          "error",
          "Error",
          "Something went wrong, please try again.",
          4000
        );
        vm.isLoading = false;
      });
  }

  function goToMap(e) {
    e.preventDefault();
    angular.element("#map").animate({ scrollTop: 0 }, 600);
  }

  function renderHtml(html_code) {
    return $sce.trustAsHtml(html_code);
  }

  /**
   * @name bookNow
   * @description Make a reservation
   */
  function bookNow() {
    if (!vm.isAuthenticated) login(null);
    else doReservation();
  }

  /**
   * @name doReservation
   * @description displays the reservation details page
   */
  function doReservation() {
    if (vm.selectedDates.length > 0) {
      angular.element("html, body").animate({ scrollTop: 0 }, 600);
      vm.checkEvent = true;
    } else {
      toaster.pop("warning", "Information", "Please select a date.", 4000);
    }
  }

  function displaySummary() {
    vm.showPayment = false;
    vm.checkEvent = true;
    angular.element("html, body").animate({ scrollTop: 0 }, 600);
  }

  /**
   * @name goToHost
   * @description redirects the user to the event page
   * @param host
   */
  function goToUser(id) {
    $state.go("view_user", {
      id: id,
    });
  }

  function updatePrice() {
    vm.isLoading = true;
    id = $stateParams.event;
    const date = vm.date
      ? moment(vm.date, "YYYY-MM-DD").format("DD-MM-YYYY")
      : moment($stateParams.date, "DD-MM-YYYY");
    vm.currency = StorageService.getItem("currency");
    HostsService.getHostbyIdAndDate(id, date, vm.currency).then(
      _setData,
      _error
    );

    function _setData(response) {
      const details = response.data.hosts[0];
      vm.details.price = details.price;
      vm.details.original_price = details.original_price;
      vm.details.initial_price = details.initial_price;
      vm.details.kids_price = details.kids_price;
      vm.details.currency = details.currency;
      setFinalPrice(vm.guests);
      setOriginalFinalPrice(vm.guests);
      vm.currency = StorageService.getItem("currency");
      vm.isLoading = false;
    }

    function _error(_err) {
      toaster.pop(
        "error",
        "Error",
        "Something went wrong, please try again.",
        4000
      );
    }
  }

  /**
   * @name getEventData
   * @param {type} id
   * @param {type} date
   * @returns {undefined}
   */
  function getEventData(id, date, init) {
    vm.eventLoaded = false;
    vm.isLoading = true;
    vm.isMobile = $(window).width() <= 767;
    let queryDate = date;
    if (!init) {
      queryDate = moment(date).format("YYYY-MM-DD");
    }
    HostsService.getHostbyIdAndDate(id, queryDate, vm.currency).then(
      _setData,
      _error
    );

    /**
     * @name _setData
     * @param {Object} response
     * @returns {undefined}
     */
    function _setData(response) {
      if (!response.data.hosts.length) {
        $state.go("home");
        return;
      }
      vm.details = response.data.hosts[0];

      // Parsed properties
      const propertiesToParse = [
        "cuisine",
        "drinks",
        "experience",
        "accommodations",
        "diets",
      ];
      for (const prop of propertiesToParse) {
        vm.details[prop] = vm.details[prop] ? JSON.parse(vm.details[prop]) : [];
      }

      vm.options = [];
      vm.details.free = vm.details.free === "1";
      vm.details.free_kids = vm.details.free_kids === "1";
      vm.details.hide_guests = vm.details.hide_guests === "1";
      vm.details.last_minute = vm.details.last_minute === "1";

      const reservations = vm.details.total_reservations
        ? Number.parseInt(vm.details.total_reservations)
        : 0;
      let max_guests = vm.details.max_guests - reservations;
      vm.details.soldout = vm.details.soldout === "1" || max_guests <= 0;
      if (max_guests <= 0) max_guests = 1;

      for (let i = 1; i <= max_guests; i++) {
        vm.options.push(i);
      }

      if (init) {
        if (!$stateParams.date && vm.details.dates.length) {
          const tempDate = moment(
            vm.details.dates[0].event_date,
            "YYYY-MM-DD"
          ).toDate();
          vm.selectedDates.push(tempDate);
          vm.selectedDateFormated = moment(tempDate).format("LL");
        }

        for (const eventDate of vm.details.dates) {
          const compareDate = moment(date, "DD-MM-YYYY").toDate();
          const currentDate = moment(
            eventDate.event_date,
            "YYYY-MM-DD"
          ).toDate();
          if (moment(currentDate).isSame(compareDate)) {
            vm.selectedDates.push(compareDate);
            vm.selectedDateFormated = moment(compareDate).format("LL");
          }
          vm.availableDates.push(moment(eventDate.event_date));
        }

        const compareDate = moment(date, "YYYY-MM-DD").toDate();
        if (moment(compareDate).isSame(vm.today) && !vm.details.last_minute) {
          vm.no_last_minute = true;
        }
      } else {
        vm.selectedDates = [date];
        vm.selectedDateFormated = moment(date).format("LL");
      }

      for (const review of vm.details.event_reviews) {
        review.date = moment(review.date).format("LL");
        review.image_path = review.image_path
          ? baseURL + review.image_path
          : `${baseURL}assets/images/dashboard/no_avatar-xlarge.jpg`;
      }

      vm.guests = 1;
      setFinalPrice(vm.guests);
      setOriginalFinalPrice(vm.guests);

      if (vm.selectedDates.length > 0) {
        const initTime = moment(
          `${moment(vm.selectedDates[0]).format("YYYY-MM-DD")} ${
            vm.details.open_hour
          }`
        );
        const endTime = moment(
          `${moment(vm.selectedDates[0]).format("YYYY-MM-DD")} ${
            vm.details.close_hour
          }`
        );
        vm.duration = endTime.diff(initTime, "hours");
      }

      if (init) {
        initMap(response.data.hosts[0].lat, response.data.hosts[0].lng);
      }

      angular.element(".carousel-inner").on("click", (event) => {
        const clickImage = event.target.style.backgroundImage;
        let path = decodeURI(clickImage.substring(4, clickImage.length - 1));
        path = path.replace(/['"]+/g, "");
        openImage(path);
      });

      vm.isLoading = false;
      vm.eventLoaded = true;

      setTimeout(() => {
        $rootScope.$emit("eventLoaded");
      }, 100);
    }

    /**
     * @name _error
     * @param {Object} _err
     * @returns {undefined}
     */
    function _error(_err) {
      vm.isLoading = false;
      toaster.pop(
        "error",
        "Error",
        "Something went wrong, please try again.",
        4000
      );
    }
  }

  /**
   * @name init
   * @description Initialize the controller
   */
  function init() {
    vm.currency = StorageService.getItem("currency");
    getUserInfo();
    id = $stateParams.event;
    const date = $stateParams.date
      ? moment($stateParams.date, "DD-MM-YYYY").format("YYYY-MM-DD")
      : moment().format("YYYY-MM-DD");
    getEventData(id, date, true);
    vm.showHostCardMobile = false;
  }

  function sanitizeHtml(html_code) {
    // Remove specific HTML tags and unwanted characters
    if (!html_code) return "";
    return html_code.replace(
      /<pre[^>]*>|<\/pre>|<!--block-->|<div[^>]*>|<\/div>|\\r\\n/g,
      ""
    );
  }

  function getSubtotal() {
    if (!vm.eventLoaded) return;
    const initial = Number(vm.details.initial_price);
    const guests = Number(vm.guests);
    return initial * guests;
  }

  function getKidsSubtotal() {
    if (!vm.eventLoaded) return;
    const kidsPrice = Number(vm.details.kids_price) || 0;
    const numberOfKids = Number(vm.kids) || 0;
    return kidsPrice * numberOfKids;
  }

  function getFeed() {
    if (!vm.eventLoaded) return;
    const initial = Number(vm.details.initial_price);
    const guests = Number(vm.guests);
    const final = Number(vm.finalPrice);
    const kidsPrice = Number(vm.details.kids_price) || 0;
    const numberOfKids = Number(vm.kids) || 0;
    return final - initial * guests - kidsPrice * numberOfKids;
  }

  function whoIsGoing() {
    if (vm.isAuthenticated) {
      $scope.guestList = vm.details.guests;
      const modalInstance = $uibModal.open({
        animation: true,
        templateUrl: "assets/js/app/modal/whoIsGoing.html",
        controller: "ModalInstanceCtrl",
        scope: $scope,
        size: 1,
        resolve: {
          data: () => $scope.data,
        },
      });
      modalInstance.result.then(() => {});
    } else {
      toaster.pop(
        "warning",
        "Unable to get guests list",
        "Please log into the system and try again.",
        4000
      );
    }
  }

  function getSlides($index) {
    let images = "";
    if (vm.isMobile) {
      const first = vm.details.images[$index].image_path;
      images +=
        `<div class='inline-carousel__view carousel-image' style='background-image: url(${first}); width: 100%; height: 247px; display: inline-block; vertical-align: top;'></div>`;
    } else if (vm.details.images.length > 1) {
      const max = vm.details.images.length;
      const first = vm.details.images[$index].image_path;
      let second = '';
      let third = '';
      let fourth = '';
      second = vm.details.images[($index + 1) % max].image_path;
      third = vm.details.images[($index + 2) % max].image_path;
      fourth = vm.details.images[($index + 3) % max].image_path;
      images +=
        `<div class='inline-carousel__view carousel-image' style='background-image: url(${first}); width: 100%; display: inline-block; vertical-align: top;'></div>`;
      images +=
        `<div class='inline-carousel__view carousel-image' style='background-image: url(${second}); width: 100%; display: inline-block; vertical-align: top;'></div>`;
      images +=
        `<div class='inline-carousel__view carousel-image' style='background-image: url(${third}); width: 100%; display: inline-block; vertical-align: top;'></div>`;
      images +=
        `<div class='inline-carousel__view carousel-image' style='background-image: url(${fourth}); width: 100%; display: inline-block; vertical-align: top;'></div>`;
      //images = images.replace('/','&#47;');
    } else if (vm.details.images.length === 1) {
      const first = vm.details.images[0].image_path;
      images +=
        `<div class='inline-carousel__view carousel-image' style='background-image: url(${first}); width: 100%; display: inline-block; vertical-align: top; left:37.5%;'></div>`;
    }
    return renderHtml(images);
  }

  function returnDiscount() {
    return Math.round((vm.finalPrice * vm.totalDiscount) / 100);
  }

  /**
   *
   * @param {type} guests
   * @returns {undefined}
   */
  function setFinalPrice(guests) {
    if (!vm.details || !vm.details.price || !guests) return;
    console.log(vm.details);
    vm.pricePerPerson = vm.details.price;
    vm.pricePerKid = vm.details.kids_price;
    const kids = vm.kids || 0;
    vm.finalPrice =
      Number(vm.pricePerPerson) * Number(guests) +
      Number(vm.pricePerKid) * Number(kids);
    if (vm.discountApplied) {
      vm.finalPrice = Math.round(
        vm.finalPrice - (vm.finalPrice * vm.totalDiscount) / 100
      );
    }
  }

  function setOriginalFinalPrice(guests) {
    if (!vm.details || !vm.details.price || !guests) return;
    const perPerson = vm.details.original_price;
    vm.originalFinalPrice = Number.parseFloat(perPerson) * Number(guests);
  }

  function setFinalPriceFromKids(kids) {
    if (!vm.details || !vm.details.price || !kids) return;
    vm.pricePerPerson = vm.details.price;
    vm.pricePerKid = vm.details.kids_price;
    vm.finalPrice =
      Number(vm.pricePerPerson) * Number(vm.guests) +
      Number(vm.pricePerKid) * Number(kids);
    if (vm.discountApplied) {
      vm.finalPrice = Math.round(
        vm.finalPrice - (vm.finalPrice * vm.totalDiscount) / 100
      );
    }
  }

  function setOriginalFinalPriceFromKids(kids) {
    if (!vm.details || !vm.details.price || !kids) return;
    const perPerson = vm.details.original_price;
    vm.originalFinalPrice = Number.parseFloat(perPerson) * Number(kids);
  }

  /**
   * @name getUserInfo
   * @description Gets user info
   */
  function getUserInfo() {
    UsersService.getUserInfo().then(_setUserInfo);

    /**
     * @name _setUserInfo
     * @description gets the user info
     * @param response
     * @private
     */
    function _setUserInfo(response) {
      vm.guest = response.data.user ? response.data.user : {};
    }
  }

  /**
   * @name initMap
   * @description initialize Google Map
   * @param lat
   * @param lng
   */
  function initMap(lat, lng) {
    const mapOptions = {
      mapTypeId: google.maps.MapTypeId.TERRAIN,
      mapTypeControl: false,
      disableDoubleClickZoom: true,
      zoomControlOptions: true,
      streetViewControl: false,
      zoom: 14,
      maxZoom: 15,
      scrollwheel: false,
      navigationControl: false,
      scaleControl: false,
      center: new google.maps.LatLng(lat, lng),
    };
    const map = new google.maps.Map(document.getElementById("map"), mapOptions);
    const marker = new google.maps.Marker({
      position: new google.maps.LatLng(lat, lng),
      title: "Event location",
      icon: normalIcon(),
      draggable: false,
    });
    marker.setMap(map);

    function normalIcon() {
      return {
        url: `${baseURL}assets/images/search/event-marker.png`,
      };
    }
  }

  /**
   * @name contactHost
   * @description Shows the message popup
   */
  function contactHost() {
    $scope.hostName = vm.details.first_name;
    const modalInstance = $uibModal.open({
      animation: true,
      templateUrl: "assets/js/app/modal/sendMessageToHost.html",
      controller: "ModalInstanceCtrl",
      scope: $scope,
      size: 1,
      resolve: {
        data: () => $scope.data,
      },
    });

    modalInstance.result.then((data) => {
      data.host = vm.details.id_user;
      data.id_event = vm.details.id;
      NotificationService.saveNotification(data)
        .then((response) => {
          if (response.data.response === "success") {
            toaster.pop(
              "success",
              "Message sent!",
              "Your message has been sent to the host.",
              4000
            );
          }
        })
        .catch(() => {
          toaster.pop(
            "error",
            "Error",
            "Something went wrong, please try again.",
            4000
          );
        });
    });
  }

  /**
   * @name login
   * @description Shows the login modal
   * @param _index
   */
  function login(_index) {
    const modalInstance = $uibModal.open({
      animation: true,
      templateUrl: "assets/js/app/modal/login.html",
      controller: "ModalInstanceCtrl",
      scope: $scope,
      size: 1,
      resolve: {
        data: () => $scope.data,
      },
    });

    modalInstance.result.then((data) => {
      let loginMethod = null;
      if (data.cover_url) {
        loginMethod = UsersService.signUpFacebook;
      } else if (data.Paa) {
        loginMethod = UsersService.signUpGoogle;
      } else {
        loginMethod = UsersService.login;
      }

      loginMethod(data)
        .then((response) => {
          if (
            response.data.response === "success" ||
            response.data.response === "login"
          ) {
            toaster.pop("success", "Logged in", "Welcome to Foodiiz.", 4000);
            const token = response.data.token;
            StorageService.setItem("token", token);

            if (
              response.data.user.unread_msg_cnt !== undefined &&
              response.data.user.unread_msg_cnt > 0
            ) {
              toaster.pop(
                "success",
                "Unread Message",
                "You have unread message(s).",
                4000
              );
            }

            UsersService.getUserInfo().then(_setUserInfo);
          } else {
            toaster.pop(
              "warning",
              "Incorrect info",
              "Please check your email and password and try again.",
              4000
            );
          }
        })
        .catch((_err) => {
          toaster.pop(
            "error",
            "Error",
            "Something went wrong, please try again.",
            4000
          );
        });

      /**
       * @name _setUserInfo
       * @description gets the user info
       * @param response
       * @private
       */
      function _setUserInfo(response) {
        $rootScope.currentUser = response.data.user;
      }
    });
  }

  /**
   * @name oneDaySelectionOnly
   * @description removes previous selected dates from selected dates array
   * @param {Event} event
   * @param {Object} date
   */
  function oneDaySelectionOnly(event, date) {
    event.preventDefault();
    const target = angular.element(event.target);
    if (target.hasClass("picker-off")) return;

    const days = angular.element(".picker-days-row").find(".picker-day");
    for (const day of days) {
      angular.element(day).removeClass("picker-selected");
    }
    target.addClass("picker-selected");

    const id = vm.details.id;
    const selectedDate = moment(date.date, "YYYY-MM-DD").toDate();
    getEventData(id, selectedDate, false);

    if (moment(selectedDate).isSame(vm.today)) {
      vm.no_last_minute = !vm.details.last_minute;
    }
  }

  /**
   * @name openImage
   * @param {string} path
   */
  function openImage(path) {
    $scope.imagePath = path;
    const modalInstance = $uibModal.open({
      animation: true,
      templateUrl: "assets/js/app/modal/image-modal.html",
      controller: "ModalInstanceCtrl",
      scope: $scope,
      size: 1,
      resolve: {
        data: () => $scope.data,
      },
    });

    modalInstance.result.then(() => {});
  }

  // ON mobile, we first show summary bar fixed on top
  function showHostCard() {
    vm.showHostCardMobile = true;
  }

  function showPicker() {
    vm.opened = !vm.opened;
  }

  function initShare() {
    if (typeof __sharethis__ !== "undefined" && __sharethis__.initialize) {
      __sharethis__.initialize();
    }
  }

  $rootScope.$watch(
    "currentUser",
    () => {
      vm.isAuthenticated = !!$rootScope.currentUser;
    },
    true
  );

  $scope.$watch(
    function watch(_scope) {
      return vm.guests;
    },
    function handle(newV) {
      if (newV) setFinalPrice(newV);
      setOriginalFinalPrice(newV);
    },
    true
  );

  $scope.$watch(
    function watch(_scope) {
      return vm.kids;
    },
    function handle(newV) {
      if (newV) setFinalPriceFromKids(newV);
      setOriginalFinalPriceFromKids(newV);
    },
    true
  );

  $scope.$on("change-chunk-size", (_event, data) => {
    if (data !== vm.chunkSize) {
      vm.details.chunkedSlides = chunk(vm.details.images, data);
      vm.chunkSize = data;
    }
  });

  $(window).resize(() => {
    $scope.$apply(() => {
      vm.isMobile = $(window).width() <= 767;
    });
  });

  $rootScope.$on("currencyChanged", updatePrice);

  $(window).on("hashchange", () => {
    if (location.hash !== "#1") {
      init();
    }
  });

  init();
}
