module.exports = NotificationController;

NotificationController.$inject = ['NotificationService', 'UsersService', '$uibModal', '$scope', 'toaster', '$state'];

function NotificationController(NotificationService, UsersService, $uibModal, $scope, toaster, $state) {

    /************************************************
     * VARIABLES
     ************************************************/
    const vm = this;
    vm.messages = [];
    vm.user = null;
    vm.messagesState = "1";
    vm.inboxMessages = [];
    vm.archivedMessages = [];
    vm.isCollapsed = true;

    /************************************************
     * METHODS
     ************************************************/
    vm.getNotification = getNotifications;
    vm.viewNotification = viewNotification;
    vm.archiveMessage = archiveMessage;
    vm.unarchiveMessage = unarchiveMessage;

    function init() {
        getUserInfo();
        getNotifications();
    }

    /**
     * @name getNotification
     * @description gets the notifications
     */
    function getNotifications() {
        vm.inboxMessages = [];
        vm.archivedMessages = [];
        vm.unreadNotificationCount = 0;
    
        NotificationService.getNotificationAll().then((response) => {
            const notifications = response.data.notifications || [];
    
            for (const notification of notifications) {
                notification.date = moment(notification.date, 'DD-MM-YYYY').format('LL');
    
                if (notification.status === '0' || notification.status === '1') {
                    vm.inboxMessages.push(notification);
                } else {
                    vm.archivedMessages.push(notification);
                }
    
                if (notification.status === '0') {
                    vm.unreadNotificationCount++;
                }
            }
        });
    }

    function viewNotification(e, notification) {
        e.preventDefault();
        e.stopPropagation();
        NotificationService.updateNotification(notification.id, 1).then(() => {
            $state.go("notification_detail", {
                id: notification.id
            });
        });
    }

    function archiveMessage(e, _notification) {
        e.preventDefault();
        e.stopPropagation();
        NotificationService.updateNotification(_notification.id, 2).then(() => {
            getNotifications();
        });
    }

    function unarchiveMessage(e, _notification) {
        e.preventDefault();
        e.stopPropagation();
        NotificationService.updateNotification(_notification.id, 1).then(() => {
            getNotifications();
        });
    }

    /**
     * @name getUserInfo
     * @description Gets user info
     */
    function getUserInfo() {
        UsersService.getUserInfo().then(_setUserInfo);

        /**
         * @name _setUserInfo
         * @description gets the user info
         * @param response
         * @private
         */
        function _setUserInfo(response) {
            vm.user = response.data.user;
        }
    }

    init();

}