module.exports = SearchHostsController;

SearchHostsController.$inject = [
  "$rootScope",
  "$scope",
  "$state",
  "$stateParams",
  "HostsService",
  "toaster",
  "$timeout",
  "$http",
  "currency_translateFilter",
  "$sce",
  "StorageService",
  "$translate",
];

function SearchHostsController(
  $rootScope,
  $scope,
  $state,
  $stateParams,
  HostsService,
  toaster,
  $timeout,
  $http,
  currency_translateFilter,
  $sce,
  StorageService,
  $translate
) {
  const vm = this;
  vm.today = moment().format("YYYY-MM-DD");
  vm.searchobject = {};
  vm.searchobject.distance = "50";
  vm.lat = 0;
  vm.lng = 0;
  vm.markers = [];
  vm.hostsList = [];
  vm.date = null;
  vm.currency = "";
  vm.location = "";
  //Filters
  vm.searchobject.guests = "1";
  vm.searchobject.seats = "1";
  vm.searchobject.price = "10";
  vm.searchobject.min_guests = "1";
  vm.searchobject.max_guests = "1";
  vm.searchobject.min_price = "1";
  vm.searchobject.max_price = "1";
  vm.searchobject.experiences = [];
  vm.experiencesOptionsLabels = [
    "event_experiences_aperitif",
    "event_experiences_breakfast",
    "event_experiences_brunch",
    "event_experiences_cooking",
    "event_experiences_dinner",
    "event_experiences_food",
    "event_experiences_lunch",
    "event_experiences_tea",
    "event_experiences_picnic",
  ];
  vm.experiencesOptions = [];
  vm.searchobject.diets = [];
  vm.dietOptionsLabels = [
    "event_diets_vegetarian",
    "event_diets_vegan",
    "event_diets_halal",
    "event_diets_gluten_free",
    "event_diets_organic",
    "event_diets_diabetes",
    "event_diets_lactose_intolerance",
    "event_diets_no_pork",
    "event_diets_kosher",
    "event_diets_no_alcohol",
    "event_diets_allergic_to_nuts",
    "event_diets_allergic_to_sesame",
    "event_diets_allergic_to_beans",
    "event_diets_allergic_to_eggs",
    "event_diets_allergic_to_fish",
    "event_diets_allergic_to_shellfish",
    "event_diets_allergic_to_gluten",
  ];
  vm.dietOptions = [];
  vm.searchobject.accommodations = [];
  vm.accommodationOptionsLabels = [
    "event_accomodation_family",
    "event_accomodation_wheelchair",
    "event_accomodation_pets",
    "event_accomodation_air",
    "event_accomodation_elevator",
    "event_accomodation_free",
    "event_accomodation_smoking",
    "event_accomodation_street",
    "event_accomodation_nearby",
    "event_accomodation_possibility_to_take",
    "event_accomodation_possibility_to_pick",
  ];
  vm.accommodationOptions = [];
  vm.multiSelectSettings = {
    template: "{{option}}",
    enableSearch: true,
    styleActive: true,
  };

  vm.isLoading = false;
  vm.showMap = true;
  vm.showFilters = false;
  vm.isMobile = false;
  vm.searchobject.displayCloseDates = false;
  vm.defaultUserImg = `${baseURL}assets/images/search/default-user.jpg`;

  vm.goToHost = goToHost;
  vm.displayMap = displayMap;
  vm.displayFilters = displayFilter;
  vm.cityChanged = cityChanged;
  vm.getFormatedDate = getFormatedDate;
  vm.getDisplayDate = getDisplayDate;
  vm.renderHtml = renderHtml;
  vm.changeMarker = changeMarker;
  vm.retrieveMarker = retrieveMarker;

  function updatePrice() {
    getNearestHosts(
      $stateParams.lat,
      $stateParams.lng,
      vm.searchobject.distance,
      vm.searchobject.date,
      vm.searchobject.cat
    );
  }

  function init() {
    vm.currency = StorageService.getItem("currency");
    if ($stateParams.lat || $stateParams.lng) {
      window.lat = $stateParams.lat;
      window.lng = $stateParams.lng;
      vm.searchobject.city = $stateParams.city;
      vm.searchobject.lat = $stateParams.lat;
      vm.searchobject.lng = $stateParams.lng;
      vm.searchobject.location = $stateParams.city;
      vm.searchobject.distance = $stateParams.distance || 50;
      vm.searchobject.date = $stateParams.date || null;
      vm.searchobject.cat = $stateParams.cat || "";
      getNearestHosts(
        $stateParams.lat,
        $stateParams.lng,
        vm.searchobject.distance,
        vm.searchobject.date,
        vm.searchobject.cat
      );
    } else {
      initGeolocation();
    }

    const translatedLabelsPromises = [
      translateLabels(vm.accommodationOptionsLabels),
      translateLabels(vm.experiencesOptionsLabels),
      translateLabels(vm.dietOptionsLabels),
    ];

    Promise.all(translatedLabelsPromises).then((translationsArray) => {
      const [
        accommodationTranslations,
        experiencesTranslations,
        dietTranslations,
      ] = translationsArray;

      vm.accommodationOptions = accommodationTranslations;
      vm.experiencesOptions = experiencesTranslations;
      vm.dietOptions = dietTranslations;

      listLoaded = true;
    });
  }

  /**
   * @name translateLabels
   * @description Translates the received labels array
   * @param {array} labelsList
   */
  function translateLabels(labelsList) {
    return $translate(labelsList);
  }

  function initGeolocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(_success, _fail);
    } else {
      toaster.pop(
        "error",
        "Error",
        "Sorry, your browser does not support geolocation services.",
        4000
      );
    }

    /**
     * @name _success
     * @param {Object} position
     * @private
     */
    function _success(position) {
      angular.element(document).trigger("init_map");
      const { latitude, longitude } = position.coords;
      const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&sensor=true&key=AIzaSyCAnsJJZJ-AaVK7OBj15fiWgHwPA1jhBIM`;

      $http({
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        url: geocodeUrl,
        method: "GET",
      })
        .then((response) => {
          const city = response.data.results[0].formatted_address;
          const category = $stateParams.cat || "";
          $state.go("search_hosts", {
            city,
            lat: latitude,
            lng: longitude,
            distance: 50,
            date: moment().format("DD-MM-YYYY"),
            cat: category,
          });
        })
        .catch((error) => {
          toaster.pop("error", "Error", error.message, 4000);
        });
    }

    /**
     * @name _fail
     * @private
     */
    function _fail() {
      toaster.pop(
        "error",
        "Error",
        "Something went wrong, please try again.",
        4000
      );
    }
  }

  /**
   * @name newFindClosest
   * @description Get the closest dates of a given date
   * @param {type} dates
   * @param {type} testDate
   * @returns {Object}
   */
  function newFindClosest(dates, testDate) {
    const before = [];
    const after = [];
    const [year, month, day] = testDate.split("-");
    const testDateObj = new Date(year, month - 1, day);

    for (const [i, date] of dates.entries()) {
      const [tarYear, tarMonth, tarDay] = date.event_date.split("-");
      const arrDate = new Date(tarYear, tarMonth - 1, tarDay);
      const diff = (arrDate - testDateObj) / (3600 * 24 * 1000);

      if (diff > 0) {
        before.push({ diff, index: i, date });
      } else {
        after.push({ diff, index: i, date });
      }
    }

    before.sort((a, b) => a.diff - b.diff);
    after.sort((a, b) => b.diff - a.diff);

    return { datesBefore: before, datesAfter: after };
  }

  /**
   * @name filterByDateAvailability
   * @description checks if the event is available for a given date
   * @param _events
   * @param date
   */
  function filterByDateAvailability(_events, date) {
    const currentDate = moment(date, "DD-MM-YYYY").format("YYYY-MM-DD");
    const filteredArray = [];
    let isAvailable = false;

    for (const _event of _events) {
      _event.displayClass = "";
      for (const [i, _date] of _event.dates.entries()) {
        if (vm.searchobject.displayCloseDates) {
          const start = moment(_date.event_date)
            .subtract(3, "days")
            .startOf("day")
            .format("YYYY-MM-DD");
          const end = moment(_date.event_date)
            .add(3, "days")
            .endOf("day")
            .format("YYYY-MM-DD");
          if (
            currentDate === _date.event_date ||
            moment(currentDate).isBetween(start, end)
          ) {
            isAvailable = true;
          }
        } else {
          if (currentDate === _date.event_date) {
            isAvailable = true;
          }
        }

        if (i === _event.dates.length - 1) {
          if (isAvailable) {
            const closestDates = newFindClosest(_event.dates, currentDate);
            if (closestDates.datesAfter.length) {
              _event.displayDate = closestDates.datesAfter[0].date.event_date;
            } else if (closestDates.datesBefore.length) {
              _event.displayDate = closestDates.datesBefore[0].date.event_date;
            } else {
              _event.displayDate = date;
            }
            filteredArray.push(_event);
          }
          isAvailable = false;
        }
      }
    }
    return filteredArray;
  }

  function filterEmpty(_events) {
    const currentDate = vm.today;
    const filteredArray = [];

    for (const _event of _events) {
      _event.displayClass = "";
      for (const [i, _date] of _event.dates.entries()) {
        if (i === _event.dates.length - 1) {
          const closestDates = newFindClosest(_event.dates, currentDate);
          if (closestDates.datesBefore.length) {
            _event.displayDate = closestDates.datesBefore[0].date.event_date;
          } else if (closestDates.datesAfter.length) {
            _event.displayDate = closestDates.datesAfter[0].date.event_date;
          } else {
            _event.displayDate = currentDate;
          }
          _event.hideDate = true;
          filteredArray.push(_event);
        }
      }
    }
    return filteredArray;
  }

  function changeMarker(index) {
    vm.markers[index].setIcon(highlightedIcon());

    function highlightedIcon() {
      return {
        url: `${baseURL}assets/images/search/map-marker_hover.png`,
      };
    }
  }

  function retrieveMarker(index) {
    vm.markers[index].setIcon(normalIcon());

    function normalIcon() {
      return {
        url: `${baseURL}assets/images/search/map-marker.png`,
      };
    }
  }

  function filterByCategory(_events, category) {
    const filteredEvents = [];
    let add = false;

    for (const _event of _events) {
      const diets = JSON.parse(_event.diets);
      if (diets) {
        for (const _diet of diets) {
          const diet = _diet.replace("event_diets_", "");
          if (diet.toLowerCase() === category.toLowerCase()) {
            add = true;
            break;
          }
        }
      }

      const experiences = JSON.parse(_event.experience);
      if (experiences) {
        for (const _experience of experiences) {
          const experience = _experience.replace("event_experiences_", "");
          if (experience.toLowerCase() === category.toLowerCase()) {
            add = true;
            break;
          }
        }
      }

      if (add) {
        filteredEvents.push(_event);
        add = false;
      }
    }

    return filteredEvents;
  }

  /**
   * @name getNearestHosts
   * @description Get the nearest hosts
   * @param {type} lat
   * @param {type} lng
   * @param {type} distance
   * @param {type} date
   * @param {type} cat
   */
  function getNearestHosts(lat, lng, distance, date, cat) {
    if (vm.isLoading) return;

    vm.isLoading = true;
    vm.hostsList = [];
    const location = vm.searchobject.location;
    vm.isMobile = $(window).width() <= 767;
    const currency = StorageService.getItem("currency");

    // If category is set
    if (cat) {
      HostsService.getHostsByFilter(currency).then(_setHostsResults);
    } else {
      HostsService.getHostsByLocation(
        lat,
        lng,
        distance,
        date,
        location,
        currency
      ).then(_setHostsResults);
    }

    // Show results
    function _setHostsResults(response) {
      angular.element(document).trigger("init_map");
      $timeout(() => {
        let mapZoom = 0;
        switch (vm.searchobject.distance) {
          case "400":
            mapZoom = 8;
            break;
          case "150":
            mapZoom = 9;
            break;
          case "50":
            mapZoom = 10;
            break;
          case "20":
            mapZoom = 11;
            break;
          case "5":
            mapZoom = 13;
            break;
          default:
            mapZoom = 11;
        }

        const array = response.data.hosts.map((value) => value);

        if (date && !cat) {
          vm.hostsList = filterByDateAvailability(array, date);
        } else {
          vm.hostsList = filterEmpty(array);
        }

        if (cat && array.length) {
          vm.hostsList = filterByCategory(vm.hostsList, cat);
        }

        if (vm.hostsList.length) {
          vm.showFilters = false;
          const mapOptions = {
            mapTypeId: google.maps.MapTypeId.TERRAIN,
            mapTypeControl: false,
            disableDoubleClickZoom: false,
            zoomControlOptions: false,
            streetViewControl: false,
            zoom: mapZoom,
            maxZoom: 15,
            center: new google.maps.LatLng(lat, lng),
          };

          vm.markers = [];
          const bounds = new google.maps.LatLngBounds();
          const map = new google.maps.Map(
            document.getElementById("map"),
            mapOptions
          );
          const infowindow = new google.maps.InfoWindow();
          const TEMPLATE =
            '<div class="map-preview"><a href="{{path}}" target="_blank"><h4 class="title">{{title}}</h4><div class="header"><img width="200" src="{{image}}" class="event-preview-img img-responsive"><span class="price">{{currency}}{{price}}</span></div></a></div>';
          let eventHTML = "";

          for (const _event of vm.hostsList) {
            _event.accommodations = JSON.parse(_event.accommodations);
            _event.cuisine = JSON.parse(_event.cuisine);
            _event.drinks = JSON.parse(_event.drinks);
            _event.experience = JSON.parse(_event.experience);
            _event.diets = _event.diets ? JSON.parse(_event.diets) : [];
            _event.displayEvent = true;
            _event.reviews_qty = _event.reviews.length
              ? _event.reviews.length
              : 0;
            _event.reviews_average = _event.reviews.length
              ? _getReviewsAverage(_event.reviews)
              : null;
            _event.soldout = _event.soldout === 1;
            _event.showSeatsLeft = !!_event.reservations;
            if (_event.showSeatsLeft) {
              _event.seatsLeft =
                Number.parseInt(vm.searchobject.max_guests, 10) -
                _event.reservations;
            }

            // Markers
            const marker = new google.maps.Marker({
              position: new google.maps.LatLng(_event.lat, _event.lng),
              animation: google.maps.Animation.BOUNCE,
              title: _event.title,
              icon: normalIcon(),
            });

            bounds.extend(marker.getPosition());
            vm.searchobject.date =
              vm.searchobject.date !== "When?"
                ? vm.searchobject.date
                : moment().format("DD-MM-YYYY");

            if (_event.free === 1) {
              const TEMPLATE1 =
                '<div class="map-preview"><a href="{{path}}" target="_blank"><h4 class="title">{{title}}</h4><div class="header"><img width="200" src="{{image}}" class="event-preview-img img-responsive"><span class="label text-capitalize label-success freeprice">FREE</span></div></a></div>';
              eventHTML = TEMPLATE1.replace(
                "{{path}}",
                `${baseURL}event/${_event.id}/${vm.searchobject.date}`
              )
                .replace("{{date}}", vm.searchobject.date)
                .replace(
                  "{{image}}",
                  `${baseURL}${_event.images[0].image_path}`
                )
                .replace("{{title}}", _event.title);
            } else {
              eventHTML = TEMPLATE.replace(
                "{{path}}",
                `${baseURL}event/${_event.id}/${vm.searchobject.date}`
              )
                .replace("{{date}}", vm.searchobject.date)
                .replace(
                  "{{image}}",
                  `${baseURL}${_event.images[0].image_path}`
                )
                .replace("{{currency}}", currency_translateFilter(vm.currency))
                .replace("{{price}}", _event.price)
                .replace("{{title}}", _event.title);
            }

            bindInfoWindow(marker, map, infowindow, eventHTML, _event.id);
            marker.setMap(map);
            vm.markers.push(marker);
          }

          map.setCenter(bounds.getCenter());
          map.fitBounds(bounds);

          if (vm.markers.length > 1) {
            map.setZoom(map.getZoom());
          } else {
            map.setZoom(10);
          }

          const max_guests = _.maxBy(vm.hostsList, "max_guests");
          const min_price = _.minBy(vm.hostsList, "price");
          const max_price = _.maxBy(vm.hostsList, "price");

          vm.searchobject.seats = 1;
          vm.searchobject.price = Number.parseInt(max_price.price, 10);

          vm.searchobject.min_guests = 1;
          vm.searchobject.max_guests = max_guests.max_guests;

          vm.searchobject.min_price = Number.parseInt(min_price.price, 10);
          vm.searchobject.max_price = Number.parseInt(max_price.price, 10);

          google.maps.event.addListener(map, "click", () => {
            infowindow.close();
          });

          const resultsCards = angular
            .element("#events-results")
            .find(".result");
          if (resultsCards.length) {
            resultsCards.hover(
              function () {
                const index = angular
                  .element("#events-results .result")
                  .index(this);
                vm.markers[index].setIcon(highlightedIcon());
              },
              function () {
                const index = angular
                  .element("#events-results .result")
                  .index(this);
                vm.markers[index].setIcon(normalIcon());
              }
            );

            const mapHeight = angular.element("#events-results").height();
            if (!vm.isMobile && mapHeight > 600) {
              angular.element("#map").css({ height: `${mapHeight}px` });
            }
          }
          vm.isLoading = false;
        } else {
          const mapOptions = {
            mapTypeId: google.maps.MapTypeId.TERRAIN,
            mapTypeControl: false,
            disableDoubleClickZoom: true,
            zoomControlOptions: false,
            streetViewControl: false,
            zoom: mapZoom,
            center: new google.maps.LatLng(lat, lng),
          };

          vm.markers = [];
          const map = new google.maps.Map(
            document.getElementById("map"),
            mapOptions
          );
          vm.isLoading = false;
        }
      }, 100);
    }

    function bindInfoWindow(marker, map, infowindow, html, id) {
      google.maps.event.addListener(marker, "click", () => {
        infowindow.close();
        infowindow.setContent(html);
        infowindow.open(map, marker);
      });
    }

    function _getReviewsAverage(_reviews) {
      const sum = _reviews.reduce(
        (acc, review) => acc + Number.parseInt(review.stars, 10),
        0
      );
      return Math.round(sum / _reviews.length);
    }

    function normalIcon() {
      return {
        url: `${baseURL}assets/images/search/map-marker.png`,
      };
    }
  }

  /**
   * @name getDisplayDate
   * @description Formats a given date
   * @param {string} _date
   * @returns {string}
   */
  function getDisplayDate(_date) {
    const date = moment(_date, "YYYY-MM-DD");
    return date.format("LL");
  }

  /**
   * @name renderHtml
   * @description Renders HTML code as trusted HTML
   * @param {string} html_code
   * @returns {object}
   */
  function renderHtml(html_code) {
    return $sce.trustAsHtml(html_code);
  }

  /**
   * @name getFormatedDate
   * @description Returns the date with a legible format
   * @param {string} _date
   * @returns {string}
   */
  function getFormatedDate(_date) {
    const date = moment(_date, "DD-MM-YYYY");
    return date.format("LL");
  }

  /**
   * @name applyFilters
   * @description Applies the dynamic filters
   */
  function applyFilters() {
    vm.isLoading = true;

    for (const host of vm.hostsList) {
      const passSeats =
        vm.searchobject.seats >= host.min_guests &&
        vm.searchobject.seats <= host.max_guests;
      const passPrice =
        host.free === 1 ||
        Number.parseInt(host.price, 10) <= vm.searchobject.price;

      host.displayEvent =
        passSeats &&
        passPrice &&
        _checkSelection(host.accommodations, vm.searchobject.accommodations) &&
        _checkSelection(host.experience, vm.searchobject.experiences) &&
        _checkSelection(host.diets, vm.searchobject.diets);
    }

    /**
     * @name _checkSelection
     * @description Filters the selected array according to the received criteria
     * @param {Array} _collection
     * @param {Array} _filters
     * @returns {Boolean}
     */
    function _checkSelection(_collection, _filters) {
      if (!_filters.length) return true;

      const matches = [];
      for (const filter of _filters) {
        for (const item of _collection) {
          if (filter === item) {
            matches.push(item);
          }
        }
      }
      return _filters.length === matches.length;
    }

    vm.isLoading = false;
  }

  /**
   * @name goToHost
   * @description Redirects the user to the event page
   * @param {Object} host
   */
  function goToHost(host) {
    const searchDate = moment(host.displayDate, "YYYY-MM-DD").format(
      "DD-MM-YYYY"
    );
    $state.go("view_event", {
      event: host.id,
      date: searchDate,
    });
  }

  /**
   *
   */
  function displayMap() {
    vm.showMap = !vm.showMap;
  }

  function displayFilter() {
    vm.showFilters = !vm.showFilters;
  }

  function cityChanged() {
    setTimeout(() => {
      $state.go("search_hosts", {
        city: vm.searchobject.location,
        lat: vm.searchobject.lat,
        lng: vm.searchobject.lng,
        date: vm.searchobject.date,
      });
    }, 500);
  }

  $scope.$watch(
    () => vm.searchobject,
    (newV, oldV) => {
      // biome-ignore lint/complexity/useOptionalChain: <explanation>
      if (newV && newV.distance && newV.distance !== oldV.distance) {
        $state.go("search_hosts", {
          city: vm.searchobject.location,
          lat: vm.searchobject.lat,
          lng: vm.searchobject.lng,
          distance: vm.searchobject.distance,
          date: vm.searchobject.date,
          cat: vm.searchobject.cat,
        });
      // biome-ignore lint/complexity/useOptionalChain: <explanation>
      } else if (newV && newV.date && newV.date !== oldV.date) {
        $state.go("search_hosts", {
          city: vm.searchobject.location,
          lat: vm.searchobject.lat,
          lng: vm.searchobject.lng,
          distance: vm.searchobject.distance,
          date: vm.searchobject.date,
          cat: vm.searchobject.cat,
        });
      } else if (newV.displayCloseDates !== oldV.displayCloseDates) {
        getNearestHosts(
          vm.searchobject.lat,
          vm.searchobject.lng,
          vm.searchobject.distance,
          vm.searchobject.date,
          vm.searchobject.cat
        );
      } else if (
        // biome-ignore lint/complexity/useOptionalChain: <explanation>
        newV &&
        newV.seats &&
        newV.seats !== oldV.seats &&
        vm.showFilters
      ) {
        applyFilters();
      } else if (
        // biome-ignore lint/complexity/useOptionalChain: <explanation>
        newV &&
        newV.price &&
        newV.price !== oldV.price &&
        vm.showFilters
      ) {
        applyFilters();
      } else if (
        // biome-ignore lint/complexity/useOptionalChain: <explanation>
        newV &&
        newV.accommodations &&
        newV.accommodations !== oldV.accommodations &&
        vm.showFilters
      ) {
        applyFilters();
      }
    },
    true
  );

  $rootScope.$on("currencyChanged", updatePrice);
  $rootScope.$on("languageChanged", updatePrice);

  init();
}
