module.exports = UserController;

UserController.$inject = ['UsersService', 'toaster', 'FileUploader', '$uibModal', '$scope', '$rootScope', '$stateParams', '$location', '$state', '$sce', 'StorageService'];

function UserController(UsersService, toaster, FileUploader, $uibModal, $scope, $rootScope, $stateParams, $location, $state, $sce, StorageService) {

    /******************
     * VARIABLES
     ******************/
    const vm = this;
    vm.user = null;
    vm.isCollapsed = true;
    vm.token = null;
    vm.files = [];
    vm.currentDate = moment().format("DD-MM-YYYY");
    vm.today = moment();
    vm.user = {};
    let token = StorageService.getItem('token');
    if (!token) token = "";
    const UPLOAD_URL = `${baseURL}Users/saveImage/${token}`;
    const uploader = new FileUploader({
        url: UPLOAD_URL
    });
    $scope.uploader = uploader;
    vm.userLoaded = false;
    vm.savingData = false;
    vm.send = false;
    vm.activeNav = 'tab-1';
    vm.paymentOption = '';
    vm.selectCountry = false;
    vm.paymentCountry = '';
    vm.bankSettings = {};
    vm.stripeAccount = {};
    vm.paypalaccount = {};
    vm.resetPayment = false;
    vm.checkobject = {};
    vm.languageSettings = {
        template: '{{option}}',
        enableSearch: false,
        styleActive: true
    };

    vm.user.languages = [];
    vm.user.password = "";
    vm.user.confirm_password = "";
    vm.user.country_code = 'USA(+1)';
    vm.passwordError = false;

    vm.languages = ["English", "French", "Italian", "German", "Portuguese", "Spanish", "Afrikanns", "Albanian", "Arabic", "Armenian", "Basque", "Bengali", "Bulgarian", "Catalan", "Cambodian", "Chinese (Mandarin)", "Croation", "Czech", "Danish", "Dutch", "Estonian", "Finnish", "Georgian", "Greek", "Gujarati", "Hebrew", "Hindi", "Hungarian", "Icelandic", "Indonesian", "Irish", "Japanese", "Javanese", "Korean", "Latin", "Latvian", "Lithuanian", "Macedonian", "Malay", "Malayalam", "Maltese", "Maori", "Marathi", "Mongolian", "Nepali", "Norwegian", "Persian", "Polish", "Punjabi", "Quechua", "Romanian", "Russian", "Samoan", "Serbian", "Slovak", "Slovenian", "Swahili", "Swedish", "Tamil", "Tatar", "Telugu", "Thai", "Tibetan", "Tonga", "Turkish", "Ukranian", "Urdu", "Uzbek", "Vietnamese", "Welsh", "Xhosa"];


    // FILTERS
    uploader.filters.push({
        name: 'imageFilter',
        fn: (item, options) => {
            const type = `|${item.type.slice(item.type.lastIndexOf('/') + 1)}|`;
            return '|jpg|png|jpeg|bmp|gif|'.indexOf(type) !== -1;
        }
    });
    // CALLBACKS
    uploader.onWhenAddingFileFailed = (item, filter, options) => {
        console.error('Failed to add file', item, filter, options);
    };

    uploader.onAfterAddingFile = (fileItem) => {
        console.info('File added', fileItem);
    };

    uploader.onAfterAddingAll = (addedFileItems) => {
        console.info('All files added', addedFileItems);
    };

    uploader.onBeforeUploadItem = (item) => {
        console.info('Before upload', item);
    };

    uploader.onProgressItem = (fileItem, progress) => {
        console.info('Upload progress', fileItem, progress);
    };

    uploader.onProgressAll = (progress) => {
        console.info('Total upload progress', progress);
    };

    uploader.onSuccessItem = (fileItem, response, status, headers) => {
        console.info('File upload success', fileItem, response, status, headers);
    };

    uploader.onErrorItem = (fileItem, response, status, headers) => {
        console.error('File upload error', fileItem, response, status, headers);
    };

    uploader.onCancelItem = (fileItem, response, status, headers) => {
        console.warn('File upload canceled', fileItem, response, status, headers);
    };

    uploader.onCompleteItem = (fileItem, response, status, headers) => {
        console.info('File upload complete', fileItem, response, status, headers);
    };

    uploader.onCompleteAll = () => {
        toaster.pop("success", "Profile Updated", "Your profile image has been successfully updated.", 4000);
        init();
    };
    /******************
     * METHODS
     ******************/
    vm.saveUser = saveUser;
    vm.goToHost = goToHost;
    vm.goToUser = goToUser;
    vm.setView = setView;
    vm.selectCountryPayout = selectCountryPayout;
    vm.verifyCountry = verifyCountry;
    vm.saveStripeAccount = saveStripeAccount;
    vm.saveBankAccount = saveBankAccount;
    vm.savePayPalAccount = savePayPalAccount;
    vm.changePaymentMethod = changePaymentMethod;
    vm.updatePassword = updatePassword;
    vm.checkPassword = checkPassword;
    vm.renderHtml = renderHtml;
    vm.openModal = openModal;
    vm.displayDeleteModal = displayDeleteModal;
    vm.reviewState = "1";
    /**
     * @name init
     * @description initializes the controller
     */
    function init() {
        if ($stateParams.id) {
            UsersService.showUserbyId($stateParams.id).then(_getUserInfo);
        } else {
            UsersService.getUserInfo().then(_setUserInfo);
            getParams();
        }
        /**
         * @name _setUserInfo
         * @description gets the user info
         * @param response
         * @private
         */
        function _setUserInfo(response) {
            const user = response.data.user;
            vm.user = user;

            // Parse languages if available, otherwise set to an empty array
            vm.user.languages = user.languages ? JSON.parse(user.languages) : [];

            // Determine payment option and set the corresponding account
            if (user.stripeaccount) {
                vm.paymentOption = 'Stripe';
                vm.user.stripeaccount = user.stripeaccount[0];
            } else if (user.bankaccount) {
                vm.paymentOption = 'Bank';
                vm.user.bankaccount = user.bankaccount[0];
            } else if (user.paypalaccount) {
                vm.paymentOption = 'PayPal';
                vm.user.paypalaccount = user.paypalaccount[0];
            } else {
                vm.paymentOption = '';
            }

            // Set user image path or default to a placeholder image
            vm.user.image_path = vm.user.image_path ? vm.user.image_path : `${baseURL}assets/images/dashboard/no_avatar-xlarge.jpg`;

            // Format birthdate
            vm.user.birthdate = moment(user.birthdate, 'YYYY-MM-DD').format('DD/MM/YYYY');

            // Initialize latitude and longitude
            vm.user.lat = 0;
            vm.user.lng = 0;

            // Convert news subscription status to boolean
            vm.user.news = user.news === '1';

            // Indicate that user data has been loaded
            vm.userLoaded = true;
        }

        /**
         * @name _getUserInfo
         * @description gets the user info
         * @param response
         * @private
         */
        function _getUserInfo(response) {
            const user = response.data.user;

            if (!user) {
                $state.go("home");
            } else {
                vm.user = user;
                vm.user.languages = user.languages ? JSON.parse(user.languages) : [];
                vm.userLoaded = true;
            }
        }

    }

    // function openEventModal() {
    //     return $scope.modalInstance = $uibModal.open({
    //         templateUrl: 'assets/js/app/modal/profile_for_event_modal.html',
    //         controller: 'ModalInstanceCtrl',
    //         scope: $scope,
    //         resolve: {
    //             data: function () {
    //                 return $scope.data;
    //             }.bind(this)
    //         }
    //     });
    // }

    function openModal() {
        $scope.modalInstance = $uibModal.open({
            templateUrl: 'assets/js/app/modal/profile_setting.html',
            controller: 'ModalInstanceCtrl',
            scope: $scope,
            resolve: {
                data: () => $scope.data
            }
        });
    }

    function displayDeleteModal() {
        const modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'assets/js/app/modal/deleteUserModal.html',
            controller: 'ModalInstanceCtrl',
            scope: $scope,
            size: 1,
            resolve: {
                data: () => $scope.data
            }
        });

        modalInstance.result.then(() => {
            UsersService.deleteUser(vm.user).then(
                (response) => {
                    const { response: res } = response.data;
                    if (res === 'success') {
                        toaster.pop("success", "User Deleted", "Your account has been successfully deleted.", 4000);
                        setTimeout(() => {
                            StorageService.deleteItem('token');
                            window.location.href = baseURL;
                        }, 1000);
                    }
                },
                (err) => {
                    console.error(err);
                    toaster.pop('error', "Error", "An error occurred while deleting your account. Please try again.", 4000);
                }
            );
        });
    }


    function getParams() {
        const params = $location.search();
        if (_.isEmpty(params)) return;

        if (params.code) {
            UsersService.getStripePermissions(params.code).then((response) => {
                const { stripe_details } = response.data;
                if (stripe_details.error) {
                    toaster.pop("warning", "Error", stripe_details.error_description, 4000);
                } else if (stripe_details.stripe_user_id) {
                    vm.user.stripeaccount = {
                        id: '',
                        account_id: stripe_details.stripe_user_id
                    };
                    UsersService.saveStripeAccount(vm.user.stripeaccount).then((response) => {
                        if (response.data.response === 'success') {
                            toaster.pop("success", "User Updated", "Your Stripe account has been saved.", 2000);
                            setTimeout(() => {
                                window.location.href = `${baseURL}users/settings`;
                            }, 2000);
                        }
                    }, (err) => {
                        console.error(err);
                        toaster.pop("error", "Save Failed", "An error occurred while saving your Stripe account. Please try again.", 4000);
                    });
                } else {
                    toaster.pop("warning", "Error", "Something went wrong, please try again.", 4000);
                }
            });
        } else {
            toaster.pop("warning", "Error", "The user has denied the request.", 4000);
        }
    }

    /**
     * @saveuser
     * @description saves the user info
     */
    function saveUser(isValid) {
        if (isValid === false) {
            alert("Something is wrong");
            return;
        }
        vm.user.news = vm.user.news ? '1' : '0';
        UsersService.saveUser(vm.user).then(
            (response) => {
                const { response: res, token } = response.data;
                if (res === 'success') {
                    toaster.pop("success", "User Updated", "Your account information has been successfully updated.", 4000);
                    StorageService.deleteItem('token');
                    StorageService.setItem('token', token);
                    init();
                }
            },
            (err) => {
                toaster.pop('error', "Update Failed", "An error occurred while updating your account. Please try again.", 4000);
            }
        );
    }

    /**
     * @name goToHost
     * @description redirects the user to the event page
     * @param host
     */
    function goToHost(host) {
        $state.go("view_event", {
            event: host.id,
            date: vm.currentDate
        });
    }

    function goToUser(id) {
        console.log(id);
        $state.go("view_user", {
            id: id
        });
    }

    /**
     *
     * @param {type} _nav
     * @returns {undefined}
     */
    function setView(_nav) {
        vm.activeNav = _nav;
    }

    /**
     *
     * @returns {undefined}
     */
    function selectCountryPayout() {
        vm.selectCountry = true;
    }

    /**
     *
     * @returns {undefined}
     */
    function verifyCountry() {
        vm.loadingCountries = true;
        UsersService.getCountriesPayout().then(_success, _error);

        function _success(response) {
            const availableCountries = response.data.countries;
            let stripeAllowed = false;
            let i;
            for (i = 0; i < availableCountries.length; i++) {
                if (availableCountries[i] === vm.paymentCountry)
                    stripeAllowed = true;
            }
            vm.paymentOption = stripeAllowed ? 'Stripe' : 'PayPal';
            vm.loadingCountries = false;
        }

        function _error(err) {
            vm.loadingCountries = false;
            console.log(err);
        }
    }

    function saveStripeAccount() {
        vm.savingData = true;
        vm.user.stripeaccount.account_id = vm.user.stripeaccount.id ? vm.user.stripeaccount.id : '';

        const _success = (response) => {
            const { response: res } = response.data;
            if (res === 'success') {
                toaster.pop("success", "Stripe Account Updated", "Your Stripe account information has been successfully saved.", 1000);
                setTimeout(() => {
                    location.reload();
                }, 1000);
            }
        };

        const _error = (err) => {
            console.error(err);
            toaster.pop('error', "Update Failed", "An error occurred while saving your Stripe account. Please try again.", 4000);
        };

        UsersService.saveStripeAccount(vm.user.stripeaccount, vm.paymentCountry).then(_success, _error);
    }

    function savePayPalAccount() {
        vm.savingData = true;
        vm.user.paypalaccount.id = vm.user.paypalaccount.id ? vm.user.paypalaccount.id : '';
        UsersService.savePayPalAccount(vm.user.paypalaccount).then(_success, _error);

        function _success(response) {
            if (response.data.response === 'success') {
                toaster.pop("success", "User updated", "Your PayPal account has been saved.", 1000);
                setTimeout(() => {
                    location.reload();
                }, 1000);
            }
        }

        function _error(err) {
            console.log(err);
        }
    }

    function saveBankAccount() {
        vm.savingData = true;
        vm.user.bankaccount.id = vm.user.bankaccount.id ? vm.user.bankaccount.id : '';
        vm.user.bankaccount.extra_info = vm.user.bankaccount.extra_info || '';
        UsersService.saveBankAccount(vm.user.bankaccount).then(_success, _error);

        function _success(response) {
            if (response.data.response === 'success') {
                toaster.pop("success", "User updated", "Your bank account has been saved.", 1000);
                if (!vm.user.bankaccount.id) {
                    setTimeout(() => {
                        location.reload();
                    }, 1000);
                } else {
                    vm.savingData = false;
                }
            }
        }

        function _error(err) {
            console.log(err);
        }
    }

    function updatePassword() {
        vm.savingData = true;
        UsersService.updatePassword(vm.user.password).then(_success, _error);

        function _success(response) {
            if (response.data.response === 'success') {
                toaster.pop("success", "User updated", "Your password has been updated successfully.", 1000);
            } else {
                toaster.pop("warning", "Error", "Your password can't be the current password.", 1000);
            }
            vm.savingData = false;
            setTimeout(() => {
                location.reload();
            }, 1000);
        }

        function _error(err) {
            console.log(err);
        }
    }

    function checkPassword() {
        if (!vm.user.password || !vm.user.confirm_password)
            return;
        if (vm.user.password !== vm.user.confirm_password) {
            vm.passwordError = true;
        } else {
            vm.passwordError = false;
        }
    }

    function renderHtml(html_code) {
        return $sce.trustAsHtml(html_code);
    }

    function changePaymentMethod() {
        vm.paymentOption = '';
        vm.resetPayment = true;
    }

    $rootScope.$on('first_profile_setting', function () {
        vm.openModal();
    });

    init();
}