module.exports = ModalInstanceCtrl;

ModalInstanceCtrl.$inject = ['$scope', '$rootScope', '$window','$uibModalInstance', 'data'];

function ModalInstanceCtrl($scope, $rootScope, $window, $uibModalInstance, data) {

    $scope.mailExists = false;
    $scope.formData = angular.copy(data);
    $scope.displayForgotPassword = false;
    $scope.loading = false;
    var googleUser = {};

    $scope.ok = ok;
    $scope.cancel = cancel;
    $scope.login = login;
    $scope.forgotPassword = forgotPassword;
    $scope.linkSignUp = linkSignUp;
    $scope.linkPremiumSignUp = linkPremiumSignUp;
    $scope.linkLogin = linkLogin;
    $scope.startApp = startApp;
    $scope.premiumHost = premiumHost;

    function startApp() {
        gapi.load('auth2', function () {
            // Retrieve the singleton for the GoogleAuth library and set up the client.
            //597088906315-qprlhb07k3o3eg6lohn9mqt7mvqc80ns.apps.googleusercontent.com
            auth2 = gapi.auth2.init({
                client_id: '900090985797-754v3malldbfteovldb3iih6f72oukut.apps.googleusercontent.com',
                cookiepolicy: 'single_host_origin',
                // Request scopes in addition to 'profile' and 'email'
                //scope: 'additional_scope'
            });
            attachSignin(document.getElementById('customBtn'));
        });
    }

    function attachSignin(element) {
        auth2.attachClickHandler(element, {},
            function (googleUser) {
                $scope.loading = true;
                var profile = googleUser.getBasicProfile();
                $uibModalInstance.close(profile);
            }, function (error) {
                console.log(JSON.stringify(error, undefined, 2));
            });
    }

    function cancel() {
        $scope.loading = true;
        $uibModalInstance.dismiss('cancel');
    }

    function ok() {
        $scope.loading = true;
        $uibModalInstance.close($scope.formData);
    }

    function linkSignUp() {
        $rootScope.$broadcast('linkSignUp');
    }

    function linkPremiumSignUp() {
        $rootScope.$broadcast('linkPremiumSignUp');
    }

    function linkLogin() {
        $rootScope.$broadcast('linkLogin');
    }

    function login() {
        $scope.loading = true;
        //FB.logout(function(){document.location.reload();});
        FB.login(function (response) {
            if (response.authResponse) {
                getUserInfo();
            } else {
                console.log('User canceled login or did not fully authorize.');
            }
        }, {scope: 'email,user_photos,user_videos'});
    }

    function getUserInfo() {
        $scope.loading = true;
        FB.api('/me',
            {fields: "email,first_name,last_name,gender"},
            function (response) {
                response.cover_url = "http://graph.facebook.com/" + response.id + "/picture?type=normal";
                $uibModalInstance.close(response);
            });
    }

    function forgotPassword() {
        $scope.displayForgotPassword = true;
    }

    function premiumHost() {
        if (!$rootScope.currentUser) {
            ok();
            linkPremiumSignUp();
        }
    }

    startApp();
}