module.exports = HostsController;

HostsController.$inject = ['FileUploader', '$scope', 'HostsService', '$state', 'toaster', '$stateParams', '$rootScope', '$sce', 'StorageService', 'UsersService', '$window'];

function HostsController(FileUploader, $scope, HostsService, $state, toaster, $stateParams, $rootScope, $sce, StorageService, UsersService, $window) {
    /************************************************
     * VARIABLES
     ************************************************/
    const vm = this;
    const UPLOAD_URL = `${baseURL}Hosts/saveImage/`;
    const uploader = new FileUploader({
        url: UPLOAD_URL
    });
    $scope.uploader = uploader;
    let isEditing = false;
    const map = null;
    const marker = null;
    let mapInit = false;
    vm.imagesQty = 0;
    vm.today = moment();
    vm.lat = 0;
    vm.lng = 0;
    vm.limitDay = moment("2018-04-10T00:00:00").format('YYYY-MM-DD');
    vm.originalHost = {};
    vm.hostobject = vm.hostobject || {};
    vm.hostobject.lat = null;
    vm.hostobject.lng = null;
    vm.hostobject.activeDate = moment().format("DD-MM-YYYY");
    vm.hostobject.selectedDates = [];
    vm.hostobject.eventdDates = [];
    vm.hostobject.venue_type = "House";
    vm.hostobject.images = [];
    vm.hostobject.after_activityOptions = [];
    vm.hostobject.status = '';
    vm.hostobject.freeEvent = false;
    vm.hostobject.free_kids = true;
    vm.hostobject.kids_price = 0;
    vm.hostobject.menu = '';
    vm.hostobject.description = '';
    vm.hostobject.canModify = true;
    vm.description_placeholder = 'Example: This food experience is a perfect combination of Tunisian and Italian cuisine. All the ingredients are organic and km0 cause they come directly from my own vegetable garden!';
    vm.menu_placeholder = '* STARTER * --- * MAIN COURSE * --- * DESSERT * --- * DRINKS *';
    vm.after_activity_placeholder = 'After dinner we can go for a nice walk so I can show you the beauty of my city';

    vm.activeNav = 'tab-1';
    vm.saveText = 'Submit for review';

    vm.hostList = [];
    vm.pendingReservationCount = 0;
    vm.pendingGuestNotificationCount = 0;
    vm.guestExperienceList = [];
    vm.isCollapsed = true;
    vm.allowStep2 = false;
    vm.allowStep3 = false;
    vm.allowSave = false;
    vm.whyhoststep = false;
    vm.displayStep1 = true;
    vm.displayStep2 = false;
    vm.displayStep3 = false;

    vm.errors = {};

    vm.send = false;
    vm.hostID = null;
    vm.hoursOptions = [];

    vm.filterStatus = "all";
    vm.filterGuestStatus = "all";
    vm.checkPrice = checkPrice;

    vm.optionsSettings = {
        template: '{{option | translate}}',
        enableSearch: true,
        styleActive: true
    };

    vm.accommodationsFilter = '';
    vm.hostobject.accommodations = [];
    vm.accommodationOptions = ["event_accomodation_family", "event_accomodation_wheelchair", "event_accomodation_pets", "event_accomodation_air", "event_accomodation_elevator", "event_accomodation_free", "event_accomodation_smoking", "event_accomodation_street", "event_accomodation_nearby", "event_accomodation_possibility_to_take", "event_accomodation_possibility_to_pick"];

    vm.drinksFilter = '';
    vm.hostobject.drinks = [];
    vm.drinksOptions = ["event_drinks_tea", "event_drinks_juice", "event_drinks_water", "event_drinks_soda", "event_drinks_beer", "event_drinks_cocktails", "event_drinks_digestif", "event_drinks_red", "event_drinks_white", "event_drinks_sparkling", "event_drinks_wine", "event_drinks_no", "event_drinks_aperitif", "event_drinks_spirits", "event_drinks_coffee"];

    vm.cuisineFilter = '';
    vm.hostobject.cuisines = [];
    vm.cuisineOptions = ["event_cuisines_traditional", "event_cuisines_tunisian", "event_cuisines_maroccan", "event_cuisines_algerian", "event_cuisines_egyptian", "event_cuisines_african", "event_cuisines_american", "event_cuisines_asian", "event_cuisines_basque", "event_cuisines_catalan", "event_cuisines_chinese", "event_cuisines_creole", "event_cuisines_eastern", "event_cuisines_french", "event_cuisines_fusion", "event_cuisines_german", "event_cuisines_greek", "event_cuisines_indian", "event_cuisines_italian", "event_cuisines_japanese", "event_cuisines_mexican", "event_cuisines_persian", "event_cuisines_north", "event_cuisines_portuguese", "event_cuisines_russian", "event_cuisines_south", "event_cuisines_spanish", "event_cuisines_thai", "event_cuisines_turkish", "event_cuisines_vietnamese", "event_cuisines_scandinavian", "event_cuisines_barbecue", "event_cuisines_danish", "event_cuisines_dutch", "event_cuisines_chilean", "event_cuisines_english", "event_cuisines_irish", "event_cuisines_cajun", "event_cuisines_jamaican", "event_cuisines_hawaiian", "event_cuisines_hungarian", "event_cuisines_icelandic", "event_cuisines_nepalese", "event_cuisines_antique", "event_cuisines_brazilian", "event_cuisines_belgian", "event_cuisines_cambodian", "event_cuisines_carrabean", "event_cuisines_european", "event_cuisines_kurdish", "event_cuisines_other", "event_cuisines_peruvian", "event_cuisines_philippines", "event_cuisines_malay", "event_cuisines_latin", "event_cuisines_malaysian", "event_cuisines_mediterranean", "event_cuisines_middle", "event_cuisines_sami", "event_cuisines_seafood", "event_cuisines_singaporean", "event_cuisines_sri"];

    vm.experiencesFilter = '';
    vm.hostobject.experiences = [];
    vm.experiencesOptions = ["event_experiences_aperitif", "event_experiences_breakfast", "event_experiences_brunch", "event_experiences_cooking", "event_experiences_dinner", "event_experiences_food", "event_experiences_lunch", "event_experiences_tea", "event_experiences_picnic", "event_experiences_chef", "event_experiences_market", "event_experiences_tour_dine", "event_experiences_food_tasting", "event_experiences_wine_testing"];

    vm.dietFilter = '';
    vm.hostobject.diets = [];
    vm.dietOptions = ["event_diets_vegetarian", "event_diets_vegan", "event_diets_halal", "event_diets_gluten_free", "event_diets_organic", "event_diets_diabetes", "event_diets_lactose_intolerance", "event_diets_no_pork", "event_diets_kosher", "event_diets_no_alcohol", "event_diets_allergic_to_nuts", "event_diets_allergic_to_sesame", "event_diets_allergic_to_beans", "event_diets_allergic_to_eggs", "event_diets_allergic_to_fish", "event_diets_allergic_to_shellfish", "event_diets_allergic_to_gluten"];

    vm.hostobject.title = '';
    vm.hostobject.openHour = '';
    vm.hostobject.closeHour = '';
    vm.hostobject.min_guests = '1';
    vm.hostobject.max_guests = '6';
    vm.hostobject.openHour = '06:00';
    vm.hostobject.closeHour = '19:00';
    vm.hostobject.dishes = [];
    vm.dish = {};
    /************************************************
     * METHODS
     ************************************************/
    vm.saveHost = saveHost;
    vm.viewHost = viewHost;
    vm.getEventById = getEventById;
    vm.getHostUser = getHostUser;
    vm.checkUserHasFullProfile = checkUserHasFullProfile;
    vm.step1 = step1;
    vm.step2 = step2;
    vm.goToStep1 = goToStep1;
    vm.goToStep2 = goToStep2;
    vm.goToStep3 = goToStep3;
    vm.cityChanged = cityChanged;
    vm.removeImage = removeImage;
    vm.clickedDay = clickedDay;
    vm.removeFromQueue = removeFromQueue;
    vm.setView = setView;
    vm.goToExperience = goToExperience;
    vm.goHome = goHome;
    vm.passwhyHost = passwhyHost;
    vm.renderHtml = renderHtml;
    vm.gotoHost = gotoHost;
    vm.translateStatus = translateStatus;
    
    vm.isMobile = false; // nabi new
    //vm.showHostCardMobile = false;
    //vm.showHostCard = showHostCard;
    
    $(window).on('beforeunload', () => {
        if (vm.displayStep2 === true) {
            return "are you sure want to leave?";
        }
        if (vm.displayStep3 === true) {
            return "are you sure want to leave?";
        }

    });
    // FILTERS
    uploader.filters.push({
        name: 'imageFilter',
        fn: (item, options) => {
            const type = `|${item.type.slice(item.type.lastIndexOf('/') + 1)}|`;
            return '|jpg|png|jpeg|bmp|gif|'.indexOf(type) !== -1;
        }
    });
    // CALLBACKS
    uploader.onWhenAddingFileFailed = (item /*{File|FileLikeObject}*/, filter, options) => {
        console.info('onWhenAddingFileFailed', item, filter, options);
    };
    uploader.onAfterAddingFile = (_fileItem) => {
        vm.imagesQty = vm.imagesQty + 1;
        checkAllowSave();
    };
    uploader.onAfterAddingAll = (addedFileItems) => {
        //console.info('onAfterAddingAll', addedFileItems);
    };
    uploader.onBeforeUploadItem = (item) => {

        item.formData.push({'id_host': vm.hostID});
        //console.info('onBeforeUploadItem', item);
    };
    uploader.onProgressItem = (fileItem, progress) => {
        //console.info('onProgressItem', fileItem, progress);
    };
    uploader.onProgressAll = (progress) => {
        //console.info('onProgressAll', progress);
    };
    uploader.onSuccessItem = (fileItem, response, status, headers) => {
        //console.info('onSuccessItem', fileItem, response, status, headers);
    };
    uploader.onErrorItem = (fileItem, response, status, headers) => {
        console.info('onErrorItem', fileItem, response, status, headers);
    };
    uploader.onCancelItem = (fileItem, response, status, headers) => {
        //console.info('onCancelItem', fileItem, response, status, headers);
        vm.imagesQty = vm.imagesQty - 1;
        checkAllowSave();
    };
    uploader.onCompleteItem = (fileItem, response, status, headers) => {
        //console.info('onCompleteItem', fileItem, response, status, headers);
    };
    uploader.onCompleteAll = () => {
        toaster.pop("success", "Host created", "Your event has been saved successfully.", 4000);
        $state.transitionTo('your_listings');
    };



    // ON mobile, we first show summary bar fixed on top
    //function showHostCard() {
    //    vm.showHostCardMobile = true;
    //}

    /*
     *  change event price input
     * 
     *  return alert dialog
     */


    function checkPrice() {
        const currency = StorageService.getItem('currency');
        const currencydata = {
            'amount': vm.hostobject.price,
            'fromcurrency': currency,
            'tocurrency': "USD"
        };
        HostsService.getCurrencyAmount(currencydata).then(
                (response) => {
                    if (response.data.amount > 40) {
                        toaster.error("FOODIIZ RECCOMEND", "Foodiiz help people to socialize and share the passion for food, so keeping the price at a decent level will help more people to have a great experience. Furthermore, we’ll have a special page where we promote the event below 40usd.", 30000);
                    }
                },
                (_err) => {
                    toaster.pop('error', "Error", "Something went wrong, please try again.", 1000);
                }
        );
    }

    /**
     * @name saveHost
     * @description saves a host in the database
     * @param {type} _status
     * @returns {undefined}
     */
    function saveHost(_status) {
        if (vm.send)
            return;
        if (_status === 'draft') {
            vm.hostobject.status = _status;
        } else {
            if (!!vm.hostobject.id && (_status === 'draft' || vm.hostobject.status === 'draft' || vm.hostobject.status === 'disabled')) {
                vm.hostobject.status = 'revision';
            } else if (!!vm.hostobject.id && vm.hostobject.status === 'rejected') {
                vm.hostobject.status = 'revision';
            } else if (!vm.hostobject.id && !vm.hostobject.status) {
                vm.hostobject.status = 'revision';
            } else if (!!vm.hostobject.id && (vm.hostobject.status === 'approved' || vm.hostobject.status === 'disabled')) {
                const isSame = angular.equals(vm.originalHost, vm.hostobject);
                if (!isSame)
                    vm.hostobject.status = 'revision';
            }
        }
        vm.send = true;
        HostsService.saveHosts(vm.hostobject).then(
                (response) => {
                    if (response.data.response === 'success') {
                        vm.hostID = vm.hostID ? vm.hostID : response.data.id_host;
                        if (uploader.queue.length) {
                            uploader.uploadAll();
                        } else {
                            toaster.pop("success", "Event saved", "Your event has been saved successfully.", 4000);
                            $state.transitionTo('your_listings');
                        }
                    }
                },
                (_err) => {
                    toaster.pop('error', "Error", "Something went wrong, please try again.", 4000);
                    vm.send = false;
                }
        );
    }

    function getHostUser() {
        HostsService.getHostUser().then(response => {
            const hosts = response.data.hosts || [];
            vm.hostList = hosts;
            vm.pendingReservationCount = _.sumBy(hosts, o => Number(o.countPendingReservation));
        });
    }

    function checkUserHasFullProfile() {
        UsersService.getUserInfo().then((response) => {
          const user = response.data.user;
          const isProfileIncomplete = !user || !user.address || !user.image_path || !user.phone_number || !user.description || !user.email;
          if (isProfileIncomplete && window.location.href.includes('user/hostsnew')) {
            toaster.pop("success", "Please complete your profile first. Your guests would love to hear about you!", 4000);
            setTimeout(() => {
                $state.go("edit_user");
            }, 4000);
          }
        });
      }
    
    /**
     * @name goToHost
     * @description redirects the user to the event page
     * @param host
     */
    function gotoHost(id) {
        $state.go("view_event", {
            event: id           
        });
    }

    /**
     * @name clickedDay
     * @description handles the click event
     * @param {type} x
     * @param {type} y
     * @returns {undefined}
     */
    function clickedDay(e, day) {
        e.preventDefault();
        const formatedDay = moment(day.date).format('YYYY-MM-DD');

        // nabi new
        if (_dayBelongsTo(formatedDay, vm.hostobject.eventdDates) && vm.hostobject.canModify !== true )
            return;
        

        if (_dayBelongsTo(formatedDay, vm.hostobject.selectedDates)) {
            let index = null;
            for (let i = 0; i < vm.hostobject.selectedDates.length; i++) {
                if (moment(vm.hostobject.selectedDates[i]).isSame(formatedDay))
                    index = i;
            }
            if (index !== null) {
                vm.hostobject.selectedDates.splice(index, 1);
                checkAllowSave();
            }
        } else {
            vm.hostobject.selectedDates.push(day.date);
            checkAllowSave();
        }

        /**
         * @name _dayBelongsTo
         * @description checks if a day exist in a given array
         * @param {type} _day
         * @param {type} _datesArray
         * @returns boolean
         */
        function _dayBelongsTo(_day, _datesArray) {
            let dayExist = false;
            for (let i = 0; i < _datesArray.length; i++) {
                if (moment(_datesArray[i]).isSame(_day))
                    dayExist = true;
            }
            return dayExist;
        }
    }

    function getEventById($id) {
        HostsService.getHostIdUser($id.data).then((response) => {
            isEditing = true;
            const event = response.data.hosts[0];
            vm.hostID = event.id;
            vm.hostobject.id = event.id;
            vm.hostobject.title = event.title;
            vm.hostobject.openHour = event.open_hour;
            vm.hostobject.closeHour = event.close_hour;
            vm.hostobject.lat = event.lat;
            vm.hostobject.lng = event.lng;
            vm.hostobject.menu = event.menu;
            vm.hostobject.description = event.description;
            vm.hostobject.after_activity = event.after_activity ? event.after_activity : '';
            vm.hostobject.currency = event.currency;
            vm.hostobject.active = event.address;
            vm.hostobject.address = event.address;
            vm.hostobject.city_name = event.city_name;
            vm.hostobject.id = event.id;
            vm.hostobject.images = event.images;
            vm.hostobject.min_guests = event.min_guests;
            vm.hostobject.max_guests = event.max_guests;
            vm.hostobject.price = Number.parseFloat(event.price);
            vm.hostobject.venue_type = event.venue_type;
            vm.hostobject.place = event.current_city;
            vm.hostobject.status = event.status;
            vm.hostobject.reservation_status = event.reservation_status; //nabi new

            vm.hostobject.free = event.free  === '1';
            vm.hostobject.hide_guests = event.hide_guests  === '1';
            vm.hostobject.last_minute = event.last_minute  === '1';
            vm.hostobject.canModify = event.canModify;

            //Parsed properties
            vm.hostobject.cuisines = event.cuisine ? JSON.parse(event.cuisine) : [];
            vm.hostobject.drinks = event.drinks ? JSON.parse(event.drinks) : [];
            vm.hostobject.experiences = event.experience ? JSON.parse(event.experience) : [];
            vm.hostobject.accommodations = event.accommodations ? JSON.parse(event.accommodations) : [];
            vm.hostobject.diets = event.diets ? JSON.parse(event.diets) : [];
            vm.imagesQty = vm.hostobject.images.length;

            vm.saveText = '';
            switch (vm.hostobject.status) {
                case '':
                    vm.saveText = 'Submit for review';
                    break;
                case 'draft':
                    vm.saveText = 'Submit for review';
                    break;
                case 'rejected':
                    vm.saveText = 'Submit for new approval';
                    break;
                case 'approved':
                    vm.saveText = 'Submit for new approval';
                    break;
                case 'disabled':
                    vm.saveText = 'Submit for new approval';
                    break;
                case 'revision':
                    vm.saveText = 'Reviewing';
                    break;
            }

            for (let i = 0; i < event.dates.length; i++) {
                vm.hostobject.selectedDates.push(moment(event.dates[i].event_date));
                vm.hostobject.eventdDates.push(moment(event.dates[i].event_date));
            }
            vm.allowStep2 = true;
            vm.allowStep3 = true;
            checkAllowSave();
            vm.originalHost = angular.copy(vm.hostobject);
            if (vm.hostobject.status === 'revision') {
                toaster.pop('warning', "Information", "You can't update an event under revision.", 4000);
            }
        });
    }

    function viewHost(host) {
        $state.go('hosts_new', {data: host});
    }

    function init() {
        if ($stateParams.data)
            getEventById($stateParams);
        
        //return;
        vm.checkUserHasFullProfile();
        vm.getHostUser();
        getGuestExperiences();
        _setHours();
        vm.errors = {};

        vm.isMobile = $(window).width() <= 767;
    }

    function getGuestExperiences() {
        HostsService.getGuestExperiences().then(response => {
            const experiences = response.data.experiences || [];
            vm.guestExperienceList = experiences;
            vm.pendingGuestNotificationCount = _.sumBy(experiences, o => Number(o.guest_checked) === 0 ? 1 : 0);
        });
    }

    function goHome() {
        $state.go('home');
    }

    /**
     * @name _setHours
     * @description set the hours for time pickers
     * @private
     */
    function _setHours() {
        vm.hoursOptions = [];
    
        for (let i = 0; i <= 23; i++) {
            const hour = i < 10 ? `0${i}` : `${i}`;
            vm.hoursOptions.push(
                { id: `${hour}:00`, hour: `${hour}:00` },
                { id: `${hour}:30`, hour: `${hour}:30` }
            );
        }
    }

    /**
     * @name initGeolocation
     * @description gets user localization
     */
    function initGeolocation() {
        const defaultPosition = {
            coords: {
                latitude: 0,
                longitude: 0
            }
        };
    
        const options = {
            enableHighAccuracy: true,
            timeout: 10000,
            maximumAge: 60000
        };
    
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(_success, _fail, options);
        } else {
            _initMap(defaultPosition);
        }
    
        /**
         * @name _success
         * @param {Object} position
         * @private
         */
        function _success(position) {
            _initMap(position);
        }
    
        /**
         * @name _fail
         * @private
         */
        function _fail() {
            _initMap(defaultPosition);
        }
    
        /**
         * @name _initMap
         * @description Initializes the map
         * @param {Object} position
         * @private
         */
        function _initMap(position) {
            const { latitude, longitude } = position.coords;
            vm.hostobject.lat = vm.hostobject.lat || latitude || 0;
            vm.hostobject.lng = vm.hostobject.lng || longitude || 0;
    
            const mapOptions = {
                mapTypeId: google.maps.MapTypeId.TERRAIN,
                mapTypeControl: false,
                disableDoubleClickZoom: true,
                zoomControl: true,
                streetViewControl: false,
                zoom: 14,
                center: new google.maps.LatLng(vm.hostobject.lat, vm.hostobject.lng)
            };
    
            const mapElement = document.getElementById('map');
            const map = new google.maps.Map(mapElement, mapOptions);
    
            const markerOptions = {
                position: new google.maps.LatLng(vm.hostobject.lat, vm.hostobject.lng),
                animation: google.maps.Animation.BOUNCE,
                title: "Event location",
                icon: {
                    url: `${baseURL}assets/images/search/map-marker.png`
                },
                draggable: true
            };
    
            const marker = new google.maps.Marker(markerOptions);
            marker.setMap(map);
    
            google.maps.event.addListener(marker, 'dragend', () => {
                vm.hostobject.lat = marker.getPosition().lat();
                vm.hostobject.lng = marker.getPosition().lng();
                map.setCenter(marker.getPosition());
            });
        }
    }

    function renderHtml(html_code) {
        return $sce.trustAsHtml(html_code);
    }

    function passwhyHost() {
        vm.whyhoststep = true;
    }
    
    function goToStep1() {
        vm.displayStep2 = false;
        vm.displayStep1 = true;
    }

    function goToStep2() {
        const activityOptions = [
            { key: 'karoke', value: 'Karoke' },
            { key: 'city_tour', value: 'City Tour' },
            { key: 'board_games', value: 'Board Games' },
            { key: 'movie', value: 'Movie/Cinema' },
            { key: 'nightout', value: 'Night Out' },
            { key: 'other', value: 'Other' }
        ];
    
        for (const option of activityOptions) {
            if (vm.hostobject[option.key]) {
                vm.hostobject.after_activityOptions.push(option.value);
            }
        }
    
        const fields = [
            { key: 'experiences', errorKey: 'experiencetype', name: 'Experience Type' },
            { key: 'drinks', errorKey: 'drink', name: 'Drinks' },
            { key: 'accommodations', errorKey: 'amenities', name: 'General amenities' },
            { key: 'cuisines', errorKey: 'cuisine', name: 'Cuisine' },
            { key: 'title', errorKey: 'title', name: 'Experience Title' },
            { key: 'price', errorKey: 'price', name: 'Price', isNullCheck: true },
            { key: 'kids_price', errorKey: 'kids_price', name: 'Kids Price', isNullCheck: true },
            { key: 'menu', errorKey: 'menu', name: 'Menu', isEmptyStringCheck: true }
        ];
    
        let error = 'The following fields are missing:<br>';
        let hasError = false;
    
        for (const field of fields) {
            const value = vm.hostobject[field.key];
            const isMissing = field.isNullCheck ? value == null : field.isEmptyStringCheck ? value === '' : !value || !value.length;
    
            vm.errors[field.errorKey] = isMissing;
    
            if (isMissing) {
                error += `- ${field.name}<br>`;
                hasError = true;
            }
        }
    
        if (hasError) {
            toaster.error('Failed', error, 4000, 'trustedHtml');
        } else {
            vm.displayStep3 = false;
            vm.displayStep1 = false;
            vm.displayStep2 = true;
    
            if (!mapInit) {
                mapInit = true;
                window.dispatchEvent(new Event('resize'));
                initGeolocation();
            }
    
            if (isEditing) {
                vm.allowStep3 = true;
            }
    
            if (location.hash !== '#1') {
                location.hash = '#1';
            }
    
            localStorage.setItem('step2', 'true');
        }
    }

    function goToStep3() {
    const venueAddressMissing = !vm.hostobject.place;
    const cityNameMissing = !vm.hostobject.city_name;

    vm.errors.place = venueAddressMissing;
    vm.errors.city = cityNameMissing;

    if (venueAddressMissing || cityNameMissing) {
        let error = 'The following fields are missing:<br>';
        if (venueAddressMissing) error += '- Venue address<br>';
        if (cityNameMissing) error += '- City name<br>';
        toaster.error('Failed', error, 4000, 'trustedHtml');
    } else {
        vm.displayStep2 = false;
        vm.displayStep3 = true;
        if (location.hash !== '#1') {
            location.hash = '#1';
        }
    }
}

    /**
     * @name step1
     */
    function step1() {
        if (isEditing) {
            vm.allowStep2 = true;
        } else {
            if (!!vm.hostobject.experiences.length && !!vm.hostobject.drinks.length && !!vm.hostobject.cuisines.length && !!vm.hostobject.min_guests && !!vm.hostobject.max_guests 
                && (vm.hostobject.title !== undefined && vm.hostobject.title.length > 10 && vm.hostobject.title.length < 50) 
                && !!vm.hostobject.openHour && !!vm.hostobject.closeHour && vm.hostobject.price != null) {
                vm.allowStep2 = true;
            } else {
                vm.allowStep2 = false;
            }
        }
    }

    /**
     * @name step2
     */
    function step2() {
        if (isEditing) {
            vm.allowStep3 = true;
        } else {
            if (!!vm.hostobject.venue_type && !!vm.hostobject.place && !!vm.hostobject.city_name) {
                vm.allowStep3 = true;
            } else {
                vm.allowStep3 = false;
            }
        }
    }

    /**
     * @name cityChanged
     * @description sets the map centered
     */
    function cityChanged() {
        const myLatlng = new google.maps.LatLng(vm.hostobject.lat, vm.hostobject.lng);
        marker.setPosition(myLatlng);
        map.setCenter(myLatlng);
        step2();
    }

    function wait(second) {
        const start = new Date().getTime();
        let end = start;
        while (end < start + second) {
            end = new Date().getTime();
        }
    }

    /**
     * @name translateStatus
     * @param type
     * @returns {string}
     */
    function translateStatus(type) {
        let status = '';
        switch(type) {
            case 'disabled':
                status = 'your_listings_show_disabled';
                break;
            case 'rejected':
                status = 'your_listings_show_rejected';
                break;
            case 'revision':
                status = 'your_listings_show_on';
                break;
            case 'draft':
                status = 'your_listings_show_draft';
                break;
            case 'approved':
                status = 'your_listings_show_approved';
                break;
        }
        return status;
    }

    function removeImage(e, _path) {
        HostsService.removeImage(_path).then((response) => {
            if (response.data.response === 'success') {
                vm.imagesQty = vm.imagesQty - 1;
                checkAllowSave();
                toaster.pop("success", "Image removed", "The event image has been removed successfully.", 4000);
                const element = angular.element(e.target).closest('.event-image');
                element.remove();
            }
        });
    }

    function removeFromQueue(item) {
        item.remove();
        vm.imagesQty = vm.imagesQty - 1;
        checkAllowSave();
    }

    function setView(_nav) {
        vm.activeNav = _nav;
    }

    function goToExperience(_token) {
        if (_token)
            $state.go("view_itinerary", {token: _token});
    }

    function checkAllowSave() {
        vm.allowSave = vm.imagesQty >= 1 && vm.hostobject.selectedDates.length > 0;
    }

    // Use AngularJS's $window service instead of jQuery for better integration
    angular.element($window).on('resize', () => {
        if (!$scope.$$phase) {
            $scope.$digest(() => {
                vm.isMobile = $window.innerWidth <= 767;
            });
        } else {
            vm.isMobile = $window.innerWidth <= 767;
        }
    });

    // Use $watch to monitor changes to $rootScope.currentUser
    $rootScope.$watch('currentUser', (newVal) => {
        if (!newVal) return;
        if (!vm.hostobject.id) {
            vm.hostobject.currency = newVal.currency;
        }
    }, true);

    // Clean up the event listener when the scope is destroyed
    $scope.$on('$destroy', () => {
        angular.element($window).off('resize');
    });

    $scope.$watch(function watch(scope) {
        return vm.hostobject;
    }, function handle(newV, oldV) {
        if (newV.free !== oldV.free) {
            if (newV.free) {
                vm.hostobject.price = 0;
            }
        }
        if (newV.free_kids !== oldV.free_kids) {
            if (newV.free_kids) {
                vm.hostobject.kids_price = 0;
            }
        }
    }, true);

    $scope.$on('$locationChangeSuccess', (event, newUrl, oldUrl)=> {
        if ( oldUrl.indexOf('user/hostsnew/#1') !== -1 && newUrl.indexOf('user/hostsnew/') !== -1 )
            toaster.pop('warning', "Information", "Please use the back button otherwise you will lose all theta already filled in"); 
    });

    init();
}