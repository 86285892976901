'use strict';

module.exports = RegisterController;

RegisterController.$inject = ['$scope', '$rootScope', '$uibModal', 'UsersService', 'toaster', '$window', '$element', 'StorageService', '$timeout'];

function RegisterController($scope, $rootScope, $uibModal, UsersService, toaster, $window, $element, StorageService, $timeout) {

    var vm = this;
    vm.videoURL = '';
    vm.user = {};
    vm.loading = false;
    vm.auth2 = {};
    vm.equalPassw = true;
    vm.facebookLogin = facebookLogin;
    vm.processForm = processForm;
    vm.goToForm = goToForm;
    vm.loginFacebook = loginFacebook;
    vm.startApp = startApp;
    vm.login = login;

    function facebookLogin() {
        vm.loading = true;
        FB.login(function (response) {
            if (response.authResponse) {
                getUserInfo();
            } else {
                console.log('User canceled login or did not fully authorize.');
            }
        }, {scope: 'email,user_photos,user_videos'});
    }

    function processForm() {
        vm.loading = true;
        if(vm.user.password === vm.user.confirmPassword) {
            vm.user.newsLetter = 1;
            vm.user.organizeFoodEvent = 1;
            UsersService.signUp(vm.user).then(
                function (response) {
                    if (response.data.response === 'success') {
                        toaster.pop("success", "Account created", "Your account was created successfully, please check your email.", 1000);
                        vm.loading = false;
                        setTimeout(function () {
                            window.location.href = baseURL;
                        }, 1000);
                    } else if (response.data.response === 'login') {
                        vm.loading = false;
                        setTimeout(function () {
                            window.location.href = baseURL;
                        }, 1000);
                    }
                },
                function (err) {
                    toaster.pop('error', "Error", "Something went wrong, please try again.", 4000);
                }
            );
            vm.equalPassw = true;
        } else {
            vm.equalPassw = false;
            vm.loading = false;
        }
    }

    function goToForm() {
        var offset = $element.find('#register').offset().top;
        $window.scrollTo(0, offset);
    }

    function updateLanguage() {
        var subdomain = window.location.hostname.split('.')[0];
        //Gets language from subdomain
        var lang = (subdomain === 'www' || subdomain === 'localhost' || subdomain === 'test') ? 'en' : subdomain;
        var myVideo = document.getElementsByTagName('video')[0];
        var vidURL = baseURL + 'assets/video/' + lang + '.mp4';
        myVideo.src = vidURL;
        setTimeout(function () {
            myVideo.load();
        }, 500);
    }

    $rootScope.$on('languageChanged', updateLanguage);

    function startApp() {
        angular.element(".navbar-collapse")[0].style.display = 'none !important';
        if (gapi.auth2 === undefined) {
            $timeout(function () {
                startApp();
            }, 5000);
        } else {
            gapi.load('auth2', function () {
                // Retrieve the singleton for the GoogleAuth library and set up the client.
                //597088906315-qprlhb07k3o3eg6lohn9mqt7mvqc80ns.apps.googleusercontent.com
                vm.auth2 = gapi.auth2.init({
                    client_id: '900090985797-754v3malldbfteovldb3iih6f72oukut.apps.googleusercontent.com',
                    cookiepolicy: 'single_host_origin',
                    // Request scopes in addition to 'profile' and 'email'
                    //scope: 'additional_scope'
                });
                attachSignin(document.getElementById('customBtn'));
            });
        }
    }

    function attachSignin(element) {
        vm.auth2.attachClickHandler(element, {},
            function (googleUser) {
                vm.loading = true;
                var profile = googleUser.getBasicProfile();
                var token = '';
                UsersService.signUpGoogle(profile).then(
                    function (response) {
                        if (response.data.response === 'success') {
                            toaster.pop("success", "Account created", "Your account was created successfully, please check your email.", 4000);
                            token = response.data.token;
                            StorageService.setItem('token', token);
                            setTimeout(function () {
                                window.location.href = baseURL;
                            }, 500);
                        } else if (response.data.response === 'login') {
                            token = response.data.token;
                            StorageService.setItem('token', token);
                            setTimeout(function () {
                                window.location.href = baseURL;
                            }, 500);
                        }
                    },
                    function (err) {
                        toaster.pop('error', "Error", "Something went wrong, please try again.", 4000);
                    }
                );
            }, function (error) {
                console.log(JSON.stringify(error, undefined, 2));
            });
    }

    function loginFacebook() {
        //FB.logout(function(){document.location.reload();});
        FB.login(function (response) {
            if (response.authResponse) {
                getUserInfo();
            } else {
                console.log('User canceled login or did not fully authorize.');
            }
        }, {scope: 'email,user_photos,user_videos'});
    }

    function getUserInfo() {
        $scope.loading = true;
        var token = '';
        FB.api('/me',
            {fields: "email,first_name,last_name,gender"},
            function (response) {
                response.cover_url = "http://graph.facebook.com/" + response.id + "/picture?type=normal";
                UsersService.signUpFacebook(response).then(
                    function (response) {
                        if (response.data.response === 'success') {
                            toaster.pop("success", "Account created", "Your account was created successfully, please check your email.", 4000);
                            token = response.data.token;
                            StorageService.setItem('token', token);
                            window.location.href = baseURL;
                        } else if (response.data.response === 'login') {
                            token = response.data.token;
                            StorageService.setItem('token', token);
                            setTimeout(function () {
                                window.location.href = baseURL;
                            }, 500);
                        }
                    },
                    function (err) {
                        toaster.pop('error', "Error", "Something went wrong, please try again.", 4000);
                    }
                );
            });
    }

    /**
     * @name login
     * @description Shows the login modal
     * @param index
     */
    function login(index) {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'assets/js/app/modal/login.html',
            controller: 'ModalInstanceCtrl',
            scope: $scope,
            size: 1,
            resolve: {
                data: function () {
                    return $scope.data;
                }.bind(this)
            }
        });

        modalInstance.result.then(function (data) {
            if (!!data.recoveryEmail) {
                UsersService.sendResetToken(data.recoveryEmail).then(
                    function (response) {
                        toaster.pop("success", "Password Reset", "Instructions were sent successfully, please check your email.", 4000);
                    },
                    function (err) {
                        toaster.pop('error', "Error", "Something went wrong, please try again.", 4000);
                    }
                );
            } else {
                UsersService.isVerified(data).then(
                    function (response) {
                        if (response.data.response === 'confirmed') {
                            var loginMethod = null;
                            if (!!data.cover_url) loginMethod = UsersService.signUpFacebook;
                            else if (!!data['Paa']) loginMethod = UsersService.signUpGoogle;
                            else loginMethod = UsersService.login;
                            loginMethod(data).then(
                                function (response) {
                                    if (response.data.response === 'success' || response.data.response === 'login') {
                                        //var user = response.data.user;
                                        var token = response.data.token;
                                        //StorageService.setItem('user', JSON.stringify(user));
                                        StorageService.setItem('token', token);
                                        setTimeout(function () {
                                            window.location.href = baseURL;
                                        }, 500);
                                    } else {
                                        toaster.pop("warning", "Incorrect info", "Please check you email and password and try again.", 4000);
                                    }
                                },
                                function (err) {
                                    toaster.pop('error', "Error", "Something went wrong, please try again.", 4000);
                                }
                            );
                        } else if (response.data.response === 'no_confirmed') {
                            toaster.pop('error', "Error", "Your email did not verified, please try email verification.", 4000);
                        } else {
                            toaster.pop('error', "Error", "Your email did not registered, please try sign up.", 4000);
                        }

                    }
                );
            }
        }).catch(function (res) {
            modalInstance.close();
        });
    }

    startApp();
}