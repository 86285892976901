module.exports = ApiRequest;

ApiRequest.$inject = ["$http", "$q", "StorageService"];

function ApiRequest($http, $q, StorageService) {
  _.extend(this, {
    callApi: callApi,
  });

  function callApi(method, url, params) {
    const deferred = $q.defer();
    const token = StorageService.getItem("token") || "";

    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    const config = {
      headers: headers,
      method: method,
      url: `${baseURL}${url}`,
      data: $.param(params),
    };

    try {
      $http(config).then(
        (response) => deferred.resolve(response),
        (error) => {
          deferred.reject(error);
          console.error(error);
        }
      );
    } catch (error) {
      deferred.reject(error);
      console.error("An unexpected error occurred:", error);
    }

    return deferred.promise;
  }
}
