'use strict';

module.exports = ThankYouController;

ThankYouController.$inject = ['$state'];

function ThankYouController($state) {

    function getElementsByClassName(classname, node)  {
        if(!node) node = document.getElementsByTagName("body")[0];
        var a = [];
        var re = new RegExp('\\b' + classname + '\\b');
        var els = node.getElementsByTagName("*");
        for(var i=0,j=els.length; i<j; i++)
            if(re.test(els[i].className))a.push(els[i]);
        return a;
    }

    function init() {
        var elements = getElementsByClassName('navbar-static-top');
        for(var i = 0; i < elements.length; i++ ){
            elements[i].style.display = "none";
        }
        setTimeout(function () {
            $state.go("whyhost");
        }, 4000);
    }

    init();
}