module.exports = HomeController;

HomeController.$inject = [
  "$state",
  "$http",
  "toaster",
  "HostsService",
  "$rootScope",
  "currency_translateFilter",
  "$sce",
  "$translate",
  "$timeout",
  "StorageService",
  "UsersService",
];

function HomeController(
  $state,
  $http,
  toaster,
  HostsService,
  $rootScope,
  currency_translateFilter,
  $sce,
  $translate,
  $timeout,
  StorageService,
  UsersService
) {
  /************************************************
   * VARIABLES
   ************************************************/
  const vm = this;
  let startDate = "";
  //Translations
  vm.country_SE = "";
  vm.country_DK = "";
  vm.country_FR = "";
  vm.country_DK = "";
  vm.country_IT = "";
  vm.country_CR = "";
  vm.country_MA = "";
  vm.country_IE = "";
  vm.country_TN = "";
  vm.country_NL = "";
  vm.isMobile = false;
  vm.premium_hosts = [];
  vm.isLoading = false;
  vm.location = "";
  vm.active = 0;
  vm.currIndex = 0;
  vm.noWrapSlides = false;
  vm.myInterval = 5000;
  vm.myTransition = false;
  vm.selectedLanguage = null;
  vm.currency = "";
  vm.slides = [
    {
      image: `${baseURL}assets/images/more-cities/las-vegas.jpg`,
      title: "Las Vegas",
    },
    {
      image: `${baseURL}assets/images/more-cities/moscou.jpg`,
      title: "Moscou",
    },
    {
      image: `${baseURL}assets/images/more-cities/vancouver.jpg`,
      title: "Vancouver",
    },
  ];
  vm.searchobject = {};
  vm.defaultUserImg = `${baseURL}assets/images/search/default-user.jpg`;

  vm.event_sold_text = "";
  vm.hosted_by_text = "";
  vm.view_event_free_text = "";
  vm.profile_reviews_text = "";
  vm.view_event_no_text = "";
  vm.displayCountries = [];
  vm.displayCities = [];
  vm.displayCategories = [];
  vm.translationReady = false;
  vm.active1 = 0;
  vm.actibe2 = 0;
  vm.active3 = 0;
  vm.translationLabels = [];
  vm.header_search_text_mobile = "";
  vm.header_search_text = "";
  vm.whiteLogo = false;

  /************************************************
   * METHODS
   ************************************************/
  vm.search = search;
  vm.getDisplayDate = getDisplayDate;
  vm.goToHost = goToHost;
  vm.getSlides = getSlides;
  vm.renderHtml = renderHtml;
  vm.showNext = showNext;
  vm.showPrev = showPrev;
  vm.getNumber = getNumber;
  vm.getCountrySlides = getCountrySlides;
  vm.getCitiesSlides = getCitiesSlides;
  vm.signNewsletter = signNewsletter;

  vm.getPrice = getPrice;
  /**
   * @name showNext
   * @description calls the next slide
   * @returns {undefined}
   */
  function showNext(parent) {
    //alert("111");
    const element = angular.element(`#${parent} .left`);
    if (element.length > 0 && element.scope && element.scope().next) {
      element.scope().next();
    }
  }

  /**
   * @name showPrev
   * @description calls the prev slide
   * @returns {undefined}
   */
  function showPrev(parent) {
    //alert("222");
    const element = angular.element(`#${parent} .right`);
    if (element.length > 0 && element.scope && element.scope().prev) {
      element.scope().prev();
    }
  }

  function search() {
    if (!vm.searchobject.lat || !vm.searchobject.lng) return;
    const date = vm.date === startDate ? null : vm.date;
    $state.go("search_hosts", {
      city: vm.location,
      lat: vm.searchobject.lat,
      lng: vm.searchobject.lng,
      distance: "50",
      date: date,
    });
  }

  /**
   * @name initGeolocation
   * @description Tries to get client location
   */
  function initGeolocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(_success, _fail);
    } else {
      toaster.pop(
        "error",
        "Error",
        "Sorry, your browser does not support geolocation services.",
        4000
      );
    }

    /**
     * @name _success
     * @param {Object} position
     * @private
     */
    function _success(position) {
      $(document).trigger("init_map");
      const { latitude, longitude } = position.coords;
      const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&sensor=true&key=AIzaSyAOpvL986L2LnLRJ6QkSrd7_Z_xfZBjwcc`;

      $http({
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        url: geocodeUrl,
        method: "GET",
      })
        .then((response) => {
          const results = response.data.results[0];
          vm.location = results.formatted_address;

          for (const component of results.address_components) {
            if (component.types.includes("country")) {
              localStorage.setItem("country_name", component.long_name);
            }
          }

          vm.searchobject.lat = latitude;
          vm.searchobject.lng = longitude;
          translateFate();

          const placeholderText = vm.isMobile
            ? vm.header_search_text_mobile
            : vm.header_search_text;
          angular
            .element("#searchTextField")
            .attr("placeholder", placeholderText);
        })
        .catch((error) => {
          console.error("Geolocation error: ", error);
        });
    }

    /**
     * @name _fail
     * @private
     */
    function _fail() {
      toaster.pop(
        "error",
        "Error",
        "Sorry, your browser does not support geolocation services.",
        4000
      );
    }
  }

  function getReviewsAverage(reviews) {
    if (!Array.isArray(reviews) || reviews.length === 0) {
      return 0; // Return 0 if reviews is not an array or is empty
    }
    const sum = reviews.reduce(
      (acc, review) => acc + Number.parseInt(review.stars, 10),
      0
    );
    return Math.round(sum / reviews.length);
  }

  /**
   * @name newFindClosest
   * @description Get the closest dates of a given date
   * @param {type} dates
   * @param {type} testDate
   * @returns {Object}
   */
  function newFindClosest(dates, testDate) {
    const before = [];
    const after = [];
    const [year, month, day] = testDate.split("-");
    const testDateObj = new Date(year, month - 1, day);

    dates.forEach((date, i) => {
      const [tarYear, tarMonth, tarDay] = date.event_date.split("-");
      const arrDate = new Date(tarYear, tarMonth - 1, tarDay);
      const diff = (arrDate - testDateObj) / (3600 * 24 * 1000);

      if (diff > 0) {
        before.push({ diff, index: i, date });
      } else {
        after.push({ diff, index: i, date });
      }
    });

    before.sort((a, b) => a.diff - b.diff);
    after.sort((a, b) => b.diff - a.diff);

    return { datesBefore: before, datesAfter: after };
  }

  /**
   * @name filterByDateAvailability
   * @description checks if the event is available for a given date
   * @param _events
   * @param date
   */
  function filterByDateAvailability(events, date) {
    const currentDate = moment(date, "DD-MM-YYYY").format("YYYY-MM-DD");
    const filteredArray = [];

    for (const event of events) {
      event.displayClass = "";
      const closestDates = newFindClosest(event.dates, currentDate);

      if (closestDates.datesBefore.length) {
        event.displayDate = closestDates.datesBefore[0].date.event_date;
      } else if (closestDates.datesAfter.length) {
        event.displayDate = closestDates.datesAfter[0].date.event_date;
      } else {
        event.displayDate = date;
      }

      filteredArray.push(event);
    }

    return filteredArray;
  }

  function getPremiumHosts() {
    if (vm.isLoading) return;
    vm.isLoading = true;
    vm.premium_hosts = [];
    const currency = StorageService.getItem("currency");
    vm.currency = currency;
    HostsService.getPremiumHosts(currency).then(_setHosts);

    /**
     * @name _setHosts
     * @description gets premium hosts
     * @param {Object} response
     */
    function _setHosts(response) {
      const array = Object.values(response.data.premium_hosts);

      vm.premium_hosts = filterByDateAvailability(array, vm.date);

      for (const _event of vm.premium_hosts) {
        _event.experience = JSON.parse(_event.experience);
        _event.displayEvent = true;
        _event.reviews_qty = _event.reviews.length || 0;
        _event.reviews_average = _event.reviews.length
          ? getReviewsAverage(_event.reviews)
          : null;
        _event.soldout = _event.soldout === "1";
        _event.free = _event.free === "1";

        const endpoint = "convert";
        const access_key = "f81b74edd45178fefe42856cf7385115";
        const from = _event.currency;
        const to = vm.currency;
        const amount = _event.price;
        const url = `https://apilayer.net/api/${endpoint}?access_key=${access_key}&from=${from}&to=${to}&amount=${amount}`;

        $http.get(url).then((response) => {
          if (response.data.success) {
            _event.price = Math.round(response.data.result);
          }
        });
      }

      angular
        .element("#events-results")
        .on("click", ".host-card-result", getHostData);
      vm.isLoading = false;
    }
  }

  function getPrice(id_event, price, fromcurrency, tocurrency) {
    const currencyData = {
      id_event,
      amount: price,
      fromcurrency,
      tocurrency,
    };

    return HostsService.getCurrencyAmountByEventID(currencyData)
      .then((response) => response.data.amount)
      .catch((err) => {
        console.error("Error fetching currency amount:", err);
        return null;
      });
  }

  /**
   * @name getHostData
   * @description gets the host data and redirects the user to the event
   * @param {Event} e
   */
  function getHostData(e) {
    e.preventDefault();
    const target = $(e.currentTarget);
    const id = target.attr("id");
    const date = target.attr("date");
    const searchDate = moment(date, "YYYY-MM-DD").format("DD-MM-YYYY");
    $state.go("view_event", { event: id, date: searchDate });
  }

  /**
   * @name goToHost
   * @description redirects the user to the event page
   * @param {Object} host
   */
  function goToHost(host) {
    const searchDate = moment(host.displayDate, "YYYY-MM-DD").format(
      "DD-MM-YYYY"
    );
    $state.go("view_event", {
      event: host.id_event,
      date: searchDate,
    });
  }

  /**
   * @name renderHtml
   * @description trusts the provided HTML code
   * @param {string} html_code
   * @returns {Object} trusted HTML
   */
  function renderHtml(html_code) {
    return $sce.trustAsHtml(html_code);
  }

  /**
   * @name getDisplayDate
   * @description formats the provided date
   * @param {string} _date
   * @returns {string} formatted date
   */
  function getDisplayDate(_date) {
    const date = moment(_date, "YYYY-MM-DD");
    return date.format("LL");
  }

  /**
   * @name getCountrySlides
   * @param $index
   * @returns {*}
   */
  function getCountrySlides($index) {
    let countries = "";
    const max = vm.displayCountries.length - 1;
    const first = vm.displayCountries[$index];
    countries += countryHTML(first);

    if (!vm.isMobile) {
      const indices = [$index + 1, $index + 2, $index + 3].map((i) =>
        i > max ? i - max - 1 : i
      );
      if ($index === max) {
        indices[0] = 0;
        indices[1] = 1;
        indices[2] = 2;
      } else if ($index === max - 1) {
        indices[0] = max;
        indices[1] = 0;
        indices[2] = 1;
      } else if ($index === max - 2) {
        indices[0] = $index + 1;
        indices[1] = $index + 2;
        indices[2] = 0;
      }

      for (const i of indices) {
        countries += countryHTML(vm.displayCountries[i]);
      }
    }

    return renderHtml(countries);
  }

  /**
   * @name countryHTML
   * @description Generates the HTML for a country card
   * @param {Object} country
   * @returns {string} HTML string for the country card
   */
  function countryHTML(country) {
    if (!country) return "";

    return `
        <div class="col-xs-12 col-sm-3 col-md-3 host-card-result single country">
            <div class="host-result">
                <div class="host-card-header summary-card-cover" style="background-image: url(${country.picture});">
                    <a href="${country.url}">
                        <div class="host-sold-out">
                            <span class="host-sold-text text-uppercase" style="color:${country.text_color};">
                                ${country.name}
                            </span>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    `;
  }

  /**
   * @name getCitiesSlides
   * @param {number} $index
   * @returns {Object} Trusted HTML
   */
  function getCitiesSlides($index) {
    let citiesHTML = "";
    const max = vm.displayCities.length - 1;
    const first = vm.displayCities[$index];
    citiesHTML += cityHTML(first);

    if (!vm.isMobile) {
      const indices = [$index + 1, $index + 2, $index + 3].map((i) =>
        i > max ? i - max - 1 : i
      );
      if ($index === max) {
        indices[0] = 0;
        indices[1] = 1;
        indices[2] = 2;
      } else if ($index === max - 1) {
        indices[0] = max;
        indices[1] = 0;
        indices[2] = 1;
      } else if ($index === max - 2) {
        indices[0] = $index + 1;
        indices[1] = $index + 2;
        indices[2] = 0;
      }

      for (const i of indices) {
        citiesHTML += cityHTML(vm.displayCities[i]);
      }
    }

    return renderHtml(citiesHTML);
  }

  /**
   * @name cityHTML
   * @description Generates the HTML for a city card
   * @param {Object} city
   * @returns {string} HTML string for the city card
   */
  function cityHTML(city) {
    if (!city) return "";

    return `
        <div class="col-xs-12 col-sm-3 col-md-3 host-card-result single cities-card">
            <a href="${city.url}">
                <div class="host-result">
                    <div class="city-pic" style="background-image: url(${city.picture})"></div>
                    <div class="city-name">
                        <span class="city-name-text text-uppercase">${city.name}</span>
                    </div>
                </div>
            </a>
        </div>
    `;
  }

  /**
   * @name getSlides
   * @param {number} $index
   * @returns {Object} Trusted HTML
   */
  function getSlides($index) {
    let hostsHTML = "";
    const max = vm.premium_hosts.length - 1;
    const first = vm.premium_hosts[$index];
    hostsHTML += hostHTML(first);

    if (!vm.isMobile) {
      const indices = [$index + 1, $index + 2, $index + 3].map((i) =>
        i > max ? i - max - 1 : i
      );
      if ($index === max) {
        indices[0] = 0;
        indices[1] = 1;
        indices[2] = 2;
      } else if ($index === max - 1) {
        indices[0] = max;
        indices[1] = 0;
        indices[2] = 1;
      } else if ($index === max - 2) {
        indices[0] = $index + 1;
        indices[1] = $index + 2;
        indices[2] = 0;
      }

      for (const i of indices) {
        hostsHTML += hostHTML(vm.premium_hosts[i]);
      }
    }

    return renderHtml(hostsHTML);
  }

  function getTranslationLabel(label) {
    const translated = vm.translationLabels[label];
    return translated ? translated : label;
  }

  /**
   * @name hostHTML
   * @description Returns the HTML for a host card
   * @param {Object} host
   * @returns {string} HTML string for the host card
   */
  function hostHTML(host) {
    if (!host) return "";

    const hostTemplate = `
        <div class="col-xs-12 col-sm-3 col-md-3 host-card-result" id="${
          host.id_event
        }" date="${host.displayDate}">
            <div class="host-result">
                <div class="host-card-header summary-card-cover" style="background-image: url(${
                  host.image
                })">
                    ${
                      host.free
                        ? `<div class="Side_Banner"><span class="Green_Banner">${vm.view_event_free_text}</span></div>`
                        : ""
                    }
                    ${
                      host.soldout
                        ? `<div class="Side_Banner"><span class="Red_Banner">${vm.event_sold_text}</span></div>`
                        : ""
                    }
                </div>
                <div class="host-card-body">
                    <div class="card-host-info">
                        <span class="host-text text-uppercase">${
                          host.city_name
                        }</span>
                        <span class="host-text">${vm.getDisplayDate(
                          host.displayDate
                        )} | ${host.open_hour}</span>
                        <span class="host-text text-dark">${host.title}</span>
                        <span class="host-text">${vm.hosted_by_text} ${
      host.first_name
    }</span>
                        ${
                          host.free
                            ? `
                            <span class="host-text"><span class="free-text">${
                              vm.view_event_free_text
                            }</span> - <b>${getTranslationLabel(
                                host.experience[0]
                              )}</b></span>
                        `
                            : `
                            <span class="host-text">${currency_translateFilter(
                              vm.currency
                            )} ${host.price} - <b>${getTranslationLabel(
                                host.experience[0]
                              )}</b></span>
                        `
                        }
                        ${
                          host.reviews.length
                            ? `
                            <span class="host-text">
                                ${Array.from({ length: host.reviews_average })
                                  .map(
                                    () =>
                                      '<span class="glyphicon glyphicon-star" aria-hidden="true"></span>'
                                  )
                                  .join("")}
                                <span style="margin-left: 5px;">${
                                  host.reviews.length
                                } ${vm.profile_reviews_text}</span>
                            </span>
                        `
                            : `
                            <span class="host-text"><span>${vm.view_event_no_text}</span></span>
                        `
                        }
                    </div>
                    <div class="card-host-pic">
                        <div class="host-img" style="background-image: url(${
                          host.host_image
                        })"></div>
                    </div>
                </div>
            </div>
        </div>
    `;

    return hostTemplate;
  }

  /**
   * @name getNumber
   * @description returns an array from a number
   * @param num
   * @returns {any[]}
   */
  function getNumber(num) {
    return new Array(num);
  }

  function init() {
    initGeolocation();

    angular.element("input").on("focusin", function () {
      const input = $(this);
      input.data("place-holder-text", input.attr("placeholder"));
      input.attr("placeholder", "");
    });

    angular.element("input").on("focusout", function () {
      const input = $(this);
      input.attr("placeholder", input.data("place-holder-text"));
    });

    vm.isMobile = $(window).width() <= 767;
    translateFate();

    angular.element(".ui-datepicker-target").removeClass("form-control");

    // Meta Tag setting, title and description
    // ngMeta.setTitle('Eat with local, Social Eating, Home Chef, Food experience - Italy, France & Denmark and anywhere around the world');
    // ngMeta.setTag('description', 'Foodiiz provides you with food experiences with locals in Italy, France, Denmark and all over the world. Come and eat great traditional food around the world & get a taste of international and local street foods. Discover Italy, France, Denmark and the rest of the world through local food experience, a new food concept that brings people on the same table.');
  }

  $(window).on("resize", () => {
    vm.isMobile = $(window).width() <= 767;
  });

  function translateFate() {
    const translateKeys = [
      "home_search_when",
      "view_event_sold",
      "view_event_hosted_by",
      "view_event_free",
      "profile_reviews_title",
      "view_event_no_reviews",
      "country_SE",
      "country_DK",
      "country_FR",
      "country_IT",
      "country_CR",
      "country_MA",
      "country_IE",
      "country_TN",
      "country_NL",
      "city_paris",
      "city_rome",
      "city_tokyo",
      "city_copenhagen",
      "city_lund",
      "city_berlin",
      "card_halal",
      "card_vegetarian",
      "card_vegan",
      "card_tea",
      "event_experiences_aperitif",
      "event_experiences_breakfast",
      "event_experiences_brunch",
      "event_experiences_cooking",
      "event_experiences_dinner",
      "event_experiences_food",
      "event_experiences_lunch",
      "event_experiences_tea",
      "event_experiences_picnic",
      "event_experiences_chef",
      "event_experiences_market",
      "event_experiences_tour_dine",
      "event_experiences_food_tasting",
      "event_experiences_wine_testing",
      "header_search_text_mobile",
      "header_search_text",
    ];

    $translate(translateKeys).then((translations) => {
      vm.translationLabels = translations;
      vm.date = translations.home_search_when;
      vm.event_sold_text = translations.view_event_sold;
      vm.hosted_by_text = translations.view_event_hosted_by;
      vm.view_event_free_text = translations.view_event_free;
      vm.profile_reviews_text = translations.profile_reviews_title;
      vm.view_event_no_text = translations.view_event_no_reviews;
      vm.header_search_text_mobile = translations.header_search_text_mobile;
      vm.header_search_text = translations.header_search_text;

      // Translating countries
      const countries = [
        {
          key: "country_SE",
          picture: "assets/images/home/home_sweden.jpg",
          url: "search/Sweden/57.6165963/15.573524899999938/400",
        },
        {
          key: "country_DK",
          picture: "assets/images/home/home_denmark.jpg",
          url: "search/Denmark/55.403692/11.355256999999938/400",
        },
        {
          key: "country_FR",
          picture: "assets/images/home/home_france.jpg",
          url: "search/France/46.768196/2.4326639999999315/400",
        },
        {
          key: "country_IT",
          picture: "assets/images/home/home_italy.jpg",
          url: "search/Italy/42.74048809999999/12.737800300000004/400",
        },
        {
          key: "country_CR",
          picture: "assets/images/home/home_costa_rica.jpg",
          url: "search/Costa%20Rica/9.9280694/-84.09072459999999/400",
        },
        {
          key: "country_MA",
          picture: "assets/images/home/home_marocco.jpg",
          url: "search/Marocco/28.9883659/-10.052749800000015/400",
        },
        {
          key: "country_IE",
          picture: "assets/images/home/home_ireland.jpg",
          url: "search/Irlanda/53.0931067/-8.122803699999963/400",
        },
        {
          key: "country_TN",
          picture: "assets/images/home/home_tunisia.jpg",
          url: "search/Tunísia/34.3547737/9.104115699999966/400",
        },
        {
          key: "country_NL",
          picture: "assets/images/home/home_holland.jpg",
          url: "search/Holland/52.0025516/5.460485199999994/400",
        },
      ];

      vm.displayCountries = countries.map((country) => ({
        name: translations[country.key],
        picture: country.picture,
        url: country.url,
        text_color: "#fff",
      }));

      // Translating cities
      const cities = [
        {
          key: "city_lund",
          picture: "assets/images/home/home_lund.jpg",
          url: "search/Lund,%20Sweden/55.70466010000001/13.191007300000024/400",
        },
        {
          key: "city_copenhagen",
          picture: "assets/images/home/home_copenhagen.jpg",
          url: "search/Copenhagen,%20Denmark/55.6760968/12.568337199999974/400",
        },
        {
          key: "city_rome",
          picture: "assets/images/home/home_rome2.jpg",
          url: "search/Rome,%20Italy/41.90270080000001/12.496235200000001/400",
        },
        {
          key: "city_paris",
          picture: "assets/images/home/home_paris.jpg",
          url: "search/Paris,%20France/48.85661400000001/2.3522219000000177/400",
        },
      ];

      vm.displayCities = cities.map((city) => ({
        name: translations[city.key],
        picture: city.picture,
        url: city.url,
      }));

      const currentDate = moment(new Date()).format("DD-MM-YYYY");
      const partialUrl = `${vm.location.replace(" ", "%20")}/${
        vm.searchobject.lat
      }/${vm.searchobject.lng}/50/${currentDate}`;

      // Translating categories
      const categories = [
        {
          key: "card_halal",
          image: "assets/images/home/home_halal.jpg",
          url: `${baseURL}search/${partialUrl}/halal`,
        },
        {
          key: "card_vegetarian",
          image: "assets/images/home/home_vegetarian.jpg",
          url: `${baseURL}search/${partialUrl}/vegetarian`,
        },
        {
          key: "card_vegan",
          image: "assets/images/home/home_vegan.jpg",
          url: `${baseURL}search/${partialUrl}/vegan`,
        },
        {
          key: "card_tea",
          image: "assets/images/home/home_teatime.jpg",
          url: `${baseURL}search/${partialUrl}/tea`,
        },
      ];

      vm.displayCategories = categories.map((category) => ({
        name: translations[category.key],
        image: category.image,
        url: category.url,
      }));

      // Init translation boolean
      $timeout(() => {
        vm.translationReady = true;
        getPremiumHosts();
      }, 1000); // 3000
    });
  }

  function resetUIElements() {
    vm.translationReady = false;
    vm.displayCountries = [];
    vm.displayCities = [];
    vm.displayCategories = [];
    translateFate();
  }

  function signNewsletter() {
    const emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (emailPattern.test(vm.email)) {
      UsersService.newsletterSignup(vm.email)
        .then((response) => {
          const { response: res } = response.data;
          if (res === "success") {
            toaster.pop(
              "success",
              "Newsletter Signup",
              "You have successfully signed up for our Newsletter.",
              4000
            );
            vm.email = "";
          } else if (res === "duplicate") {
            toaster.pop(
              "error",
              "Error",
              "Your email is already registered for newsletters.",
              4000
            );
          } else {
            toaster.pop(
              "error",
              "Error",
              "Something went wrong, please try again.",
              4000
            );
          }
        })
        .catch(() => {
          toaster.pop(
            "error",
            "Error",
            "Something went wrong, please try again.",
            4000
          );
        });
    } else {
      toaster.pop("error", "Error", "Invalid Email Format", 4000);
    }
  }

  $rootScope.$on("languageChanged", resetUIElements);
  $rootScope.$on("currencyChanged", getPremiumHosts);

  $rootScope.$watch(
    "globalSettings",
    () => {
      if ($rootScope.globalSettings) {
        vm.whiteLogo = !!$rootScope.globalSettings.whiteLogo;
      }
    },
    true
  );

  init();
}
