'use strict';

module.exports = LandingController;

LandingController.$inject = ['$scope', '$rootScope', '$uibModal', 'UsersService', 'toaster', '$window', '$element', 'StorageService', '$timeout', '$state'];

function LandingController($scope, _$rootScope, $uibModal, UsersService, toaster, _$window, _$element, StorageService, $timeout, $state) {
    /************************************************
     * VARIABLES
     ************************************************/
    var vm = this;
    vm.videoURL = '';
    vm.showButton = true;
    vm.loading = false;
    vm.user = {};
    vm.playVid = playVid;
    vm.playButt = playButt;
    vm.loginFacebook = loginFacebook;
    vm.startApp = startApp;
    vm.processForm = processForm;
    vm.login = login;

    function playButt() {
        var pB = document.getElementsByTagName('video')[0];
        if (pB.paused) {
            pB.play();
            vm.showButton = false;
        } else {
            pB.pause();
            vm.showButton = true;
        }
    }

    function playVid() {
        var pB = document.getElementsByTagName('video')[0];
        vm.showButton = !pB.paused;
    }

    function getElementsByClassName(classname, node)  {
        if(!node) node = document.getElementsByTagName("body")[0];
        var a = [];
        var re = new RegExp('\\b' + classname + '\\b');
        var els = node.getElementsByTagName("*");
        for(var i=0,j=els.length; i<j; i++)
            if(re.test(els[i].className))a.push(els[i]);
        return a;
    }

    function init() {
        var elements = getElementsByClassName('navbar-static-top');
        for(var i = 0; i < elements.length; i++ ){
            elements[i].style.display = "none";
        }
        document.getElementById('main_content').style.paddingTop = 0;
        var subdomain = window.location.hostname.split('.')[0];
        //Gets language from subdomain
        var lang = (subdomain === 'www' || subdomain === 'localhost' || subdomain === 'test') ? 'en' : subdomain;
        var myVideo = document.getElementsByTagName('video')[0];
        var vidURL = baseURL + 'assets/video/' + lang + '.mp4';
        myVideo.src = vidURL;
        myVideo.autoplay = false;
        setTimeout(function () {
            myVideo.load();

        }, 500);
        // Meta Tag setting, title and description
        //ngMeta.setTitle('How Foodiiz Works? Be a Guest or Host - Foodiiz Community');
        //ngMeta.setTag('description', 'Explore hundreds of fantastic Foodiiz culinary experiences around the world. Foodiiz help you step by step to do your own dinner, lunch, breakfast, or specific event. Be a guest or welcome people like a chef at your table. Get in touch with us today to find out more.');
    }

    function startApp() {
        if (gapi.auth2 === undefined) {
            $timeout(function () {
                startApp();
            }, 5000);
        } else {
            gapi.load('auth2', function () {
                // Retrieve the singleton for the GoogleAuth library and set up the client.
                //597088906315-qprlhb07k3o3eg6lohn9mqt7mvqc80ns.apps.googleusercontent.com
                vm.auth2 = gapi.auth2.init({
                    client_id: '900090985797-754v3malldbfteovldb3iih6f72oukut.apps.googleusercontent.com',
                    cookiepolicy: 'single_host_origin',
                    // Request scopes in addition to 'profile' and 'email'
                    //scope: 'additional_scope'
                });
                attachSignin(document.getElementById('customBtn'));
            });
        }
    }

    function attachSignin(element) {
        vm.auth2.attachClickHandler(element, {},
            function (googleUser) {
                vm.loading = true;
                var profile = googleUser.getBasicProfile();
                var token = '';
                UsersService.signUpGoogle(profile).then(
                    function (response) {
                        if (response.data.response === 'success') {
                            toaster.pop("success", "Account created", "Your account was created successfully, please check your email.", 4000);
                            token = response.data.token;
                            StorageService.setItem('token', token);
                            setTimeout(function () {
                                $state.go("thank_you");
                            }, 500);
                        } else if (response.data.response === 'login') {
                            token = response.data.token;
                            StorageService.setItem('token', token);
                            setTimeout(function () {
                                window.location.href = baseURL;
                            }, 500);
                        }
                    },
                    function (_err) {
                        toaster.pop('error', "Error", "Something went wrong, please try again.", 4000);
                    }
                );
            }, function (error) {
                console.log(JSON.stringify(error, undefined, 2));
            });
    }

    function loginFacebook() {
        //FB.logout(function(){document.location.reload();});
        FB.login(function (response) {
            if (response.authResponse) {
                getUserInfo();
            } else {
                console.log('User canceled login or did not fully authorize.');
            }
        }, {scope: 'email,user_photos,user_videos'});
    }

    function getUserInfo() {
        $scope.loading = true;
        var token = '';
        FB.api('/me',
            {fields: "email,first_name,last_name,gender"},
            function (response) {
                response.cover_url = "http://graph.facebook.com/" + response.id + "/picture?type=normal";
                UsersService.signUpFacebook(response).then(
                    function (response) {
                        if (response.data.response === 'success') {
                            toaster.pop("success", "Account created", "Your account was created successfully, please check your email.", 4000);
                            token = response.data.token;
                            StorageService.setItem('token', token);
                            $state.go("whyhost");
                        } else if (response.data.response === 'login') {
                            token = response.data.token;
                            StorageService.setItem('token', token);
                            setTimeout(function () {
                                window.location.href = baseURL;
                            }, 500);
                        }
                    },
                    function (_err) {
                        toaster.pop('error', "Error", "Something went wrong, please try again.", 4000);
                    }
                );
            });
    }

    function processForm() {
        vm.loading = true;
        vm.user.newsLetter = 1;
    
        // Get subdomain from the hostname
        const subdomain = window.location.hostname.split('.')[0];
    
        // Determine language based on subdomain
        vm.user.lang = (subdomain === 'www' || subdomain === 'localhost' || subdomain === 'test') ? 'en' : subdomain;
    
        // Call the sign-up service
        UsersService.signUp(vm.user).then(
            (response) => {
                const responseData = response.data.response;
    
                if (responseData === 'success') {
                    toaster.pop("success", "Account Created", "Your account was created successfully. Please check your email for verification instructions.", 4000);
                    setTimeout(() => {
                        $state.go("thank_you");
                    }, 1000);
                } else if (responseData === 'login') {
                    setTimeout(() => {
                        window.location.href = baseURL;
                    }, 1000);
                } else {
                    toaster.pop('error', "Error", "An unexpected response was received. Please try again.", 4000);
                }
            },
            (_err) => {
                toaster.pop('error', "Error", "An error occurred while creating your account. Please try again later.", 4000);
            }
        ).finally(() => {
            vm.loading = false;
        });
    }

    async function login(_index) {
        try {
            const modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'assets/js/app/modal/login.html',
                controller: 'ModalInstanceCtrl',
                scope: $scope,
                size: 1,
                resolve: {
                    data: () => $scope.data
                }
            });
    
            const data = await modalInstance.result;
    
            if (data.recoveryEmail) {
                await handlePasswordReset(data.recoveryEmail);
            } else {
                await handleLogin(data);
            }
        } catch (error) {
            modalInstance.close();
        }
    }
    
    async function handlePasswordReset(recoveryEmail) {
        try {
            await UsersService.sendResetToken(recoveryEmail);
            toaster.pop("success", "Password Reset", "Password reset instructions have been sent to your email. Please check your inbox.", 4000);
        } catch (error) {
            toaster.pop('error', "Error", "An error occurred while sending the reset instructions. Please try again later.", 4000);
        }
    }
    
    async function handleLogin(data) {
        try {
            const response = await UsersService.isVerified(data);
            const responseData = response.data.response;
        
            if (responseData === 'confirmed') {
                await performLogin(data);
            } else if (responseData === 'no_confirmed') {
                toaster.pop('error', "Verification Required", "Your email is not verified. Please check your email for verification instructions.", 4000);
            } else {
                toaster.pop('error', "Registration Required", "Your email is not registered. Please sign up to create an account.", 4000);
            }
        } catch (error) {
            toaster.pop('error', "Error", "An unexpected error occurred. Please try again later.", 4000);
        }
    }
    
    async function performLogin(data) {
        try {
            let loginMethod;
    
            if (data.cover_url) {
                loginMethod = UsersService.signUpFacebook;
            } else if (data['Paa']) {
                loginMethod = UsersService.signUpGoogle;
            } else {
                loginMethod = UsersService.login;
            }
    
            const response = await loginMethod(data);
            const responseData = response.data.response;
    
            if (responseData === 'success' || responseData === 'login') {
                const token = response.data.token;
                StorageService.setItem('token', token);
                setTimeout(() => {
                    window.location.href = baseURL;
                }, 500);
            } else {
                toaster.pop("warning", "Incorrect info", "Please check your email and password and try again.", 4000);
            }
        } catch (error) {
            toaster.pop('error', "Error", "Something went wrong, please try again.", 4000);
        }
    }

    init();
}