window.$ = require("jquery");
window.jQuery = $;
window._ = require("lodash");
window.moment = require("moment/min/moment-with-locales");
window.CryptoJS = require("crypto-js");

const angular = require("angular");
//const ng_animate = require('angular-animate');
const ng_touch = require("angular-touch");
const ng_bootstrap = require("angular-ui-bootstrap");
const ng_messages = require("angular-messages");
const ng_resource = require("angular-resource");
const ng_sanitize = require("angular-sanitize"); // angular-sanitize used by angular-translate
const ng_translate = require("angular-translate");
const ng_translate_locale = require("angular-dynamic-locale");
const ng_translate_files = require("angular-translate-loader-static-files");
const ng_router = require("angular-ui-router");
const ng_toaster = require("angularjs-toaster");
const ng_stripe = require("angular-stripe");
const mdp = require("multiple-date-picker");
const ng_trix = require("angular-trix");
require("angular-lazy-load/dist/js/AngularLazyLoad.min");
require("angular-cookies");
const ng_localstore = require("angular-translate-storage-local");
//require('ng-meta');
require("jquery-touchswipe/jquery.touchSwipe.min");
require("angularjs-dropdown-multiselect/dist/angularjs-dropdown-multiselect.min");
require("angular-file-upload/dist/angular-file-upload");
require("../../js/libs/bootstrap-datetimepicker");
require("angular-ui-bootstrap/dist/ui-bootstrap-tpls.js");
require("angular-trix/dist/angular-trix.js");
require("../../js/libs/slider");
require("angular-translate-handler-log");
require("jquery-touchswipe/jquery.touchSwipe.min");
require("angular-cookie-law/dist/angular-cookie-law.js");
require("../../js/libs/gapi");

angular
    .module("foodiiz", [
    ng_touch,
    ng_bootstrap,
    ng_router,
    ng_resource,
    ng_messages,
    ng_sanitize,
    "pascalprecht.translate",
    ng_localstore,
    "ngCookies",
    ng_translate_locale,
    ng_translate_files,
    ng_toaster,
    "ng-bootstrap3-datepicker",
    "angularFileUpload",
    "angularjs-dropdown-multiselect",
    mdp,
    ng_stripe,
    "ui.bootstrap-slider",
    "angularTrix",
    "angular-cookie-law",
    "angular-lazy-load",
    ])
    .config(require("./app.routes.js"))
    .controller("ModalInstanceCtrl", require("./modal/modalCtrl"))
    .controller("ratingCtrl", require("./modal/ratingCtrl"))
    .controller(
    "NotificationController",
    require("./notification/notificationCtrl")
    )
    .controller(
    "NotificationDetailController",
    require("./notification/notificationDetailCtrl")
    )
    .controller("HomeController", require("./home/homeCtrl"))
    .controller("SearchController", require("./globals/navCtrl"))
    .controller("FooterController", require("./globals/footerCtrl"))
    .controller("SearchHostsController", require("./search/searchHost"))
    .controller("ViewEventController", require("./events/viewEventCtrl"))
    .controller("ViewItineraryController", require("./events/eventItineraryCtrl"))
    .controller("DashboardController", require("./dashboard/dashboardCtrl"))
    .controller("HostsController", require("./hosts/hostsCtrl"))
    .controller("GiftCardController", require("./giftcard/giftcardCtrl"))
    .controller("EventDatesController", require("./hosts/eventsDatesCtrl"))
    .controller("UserController", require("./users/userCtrl"))
    .controller(
    "ConfirmationController",
    require("./users/emailConfirmationCtrl")
    )
    .controller("PasswordController", require("./users/passwordResetCtrl"))
    .controller("ContactController", require("./contact/contactCtrl"))
    .controller("WhyHostController", require("./why-host/whyHostCtrl"))
    .controller("FAQController", require("./faq/faqCtrl"))
    .controller("HowItWorksController", require("./how-it-works/howItWorksCtrl"))
    .controller("WelcomeController", require("./welcome/welcomeCtrl"))
    .controller("RegisterController", require("./register/registerCtrl"))
    .controller("BlogController", require("./blog/blogCtrl"))
    .controller("LandingController", require("./landing/landingCtrl"))
    .controller("ThankYouController", require("./thank-you-page/thankyouCtrl"))
    .directive("googleplace", require("../directives/googleplace.directive"))
    .directive("file", require("../directives/fileInput"))
    .directive("ngThumb", require("../directives/ngThumb"))
    .directive("creditCardType", require("../directives/creditCard.directive"))
    .directive("onlyNumbers", require("../directives/onlyNumbers.directive"))
    .directive("numbersOnly", require("../directives/numbersOnly.directive"))
    .directive("pwCheck", require("../directives/pwCheck.directive"))
    .directive("emailExists", require("../directives/emailExist.directive"))
    .directive("scroll", require("../directives/scroll.directive"))
    .directive("slideShow", require("../directives/slideShow.directive"))
    .directive(
    "setMobileSticky",
    require("../directives/setMobileSticky.directive")
    )
    .directive("checkStrength", require("../directives/checkStrength.directive"))
    .service("UsersService", require("../services/users"))
    .service("HostsService", require("../services/hosts"))
    .service("ApiRequest", require("../services/apiService"))
    .service("NotificationService", require("../services/notification"))
    .service("AuthenticationService", require("../services/authentication"))
    .service("ContactService", require("../services/contact"))
    .service("PaginationService", require("../services/pagination"))
    .service("StorageService", require("../services/session"))
    .service("BlogService", require("../services/blog"))
    .service("MetaTagsService", require("../services/metatags"))
    .factory("TranslationService", require("../services/translation"))
    .filter("currency_translate", require("../filters/currencySymbol.filter"))
    .filter("date_format", require("../filters/dateFormat.filter"))
    .run(
    ($rootScope, $state, AuthenticationService, toaster, MetaTagsService) => {
        $rootScope.currentUser = null;
        $rootScope.globalSettings = {};

        $rootScope.$on("$stateChangeStart", (event, toState) => {
        if (navigator.onLine) {
            AuthenticationService.isAuthenticated().then((response) => {
            const user = response.data.user;
            $rootScope.globalSettings = {};

            if (toState.authenticate && !response.data.session) {
                const redirect = document.URL;
                localStorage.setItem("redirect", redirect);
                $state.transitionTo("home");
                $rootScope.$broadcast("non-authenticate");
                $rootScope.globalSettings.whiteLogo = toState.whiteLogo;
                event.preventDefault();
            } else if (toState.authenticate && !!user && user.active === "0") {
                $rootScope.currentUser = null;
                toaster.pop(
                "warning",
                "Error",
                "Your account is suspended, please contact us for more info.",
                4000
                );
                $state.transitionTo("home");
                $rootScope.globalSettings.whiteLogo = toState.whiteLogo;
                event.preventDefault();
            } else {
                angular.element("html, body").animate({ scrollTop: 0 }, 600);
                $rootScope.currentUser = user ? user : null;
                $rootScope.globalSettings.whiteLogo = toState.whiteLogo;
            }
            });
            setTimeout(() => {
            angular.element(".loading-app").hide();
            }, 500);
        } else {
            toaster.pop("warning", "Warning", "No internet connection.", 4000);
        }

        MetaTagsService.setDefaultTags({
            title:
            "Eat with local, Social Eating, Home Chef, Food experience - Italy, France & Denmark and anywhere around the world",
            author: "Foodiiz",
            description:
            "Foodiiz provides you with food experiences with locals in Italy, France, Denmark and all over the world. Come and eat great traditional food around the world & get a taste of international and local street foods. Discover Italy, France, Denmark and the rest of the world through local food experience, a new food concept that brings people on the same table.",
            keywords: "food, food sharing, foodiiz, social eating",
            "google-site-verification":
            "8ek_nlhSn_yVnBfEsLVc3TipwwAdsPyhGQafoM-6RYM",
            viewport:
            "width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no",
            googlebot: "all",
            bingbot: "all",
            robots: "all",
            "og:url": "https://www.foodiiz.com",
            "og:site_name": "Foodiiz",
            "og:title": "Foodiiz",
            "og:description": "FOOD EXPERIENCES WITH LOCALS AROUND THE WORLD",
            "og:image": `${baseURL}assets/images/web_logo.png`,
            "twitter:card": "summary",
            "twitter:title": "Foodiiz",
            "twitter:description":
            "FOOD EXPERIENCES WITH LOCALS AROUND THE WORLD",
            "twitter:site": `${baseURL}assets/images/web_logo.png`,
        });
        });
    }
    )
    .config((stripeProvider, $translateProvider, lazyLoadProvider) => {
    stripeProvider.setPublishableKey("pk_live_ihbR6DUKm1BU29a6mWkx8tqm");
    lazyLoadProvider.setUseIntersectionObserver(true);

    const lang = localStorage.getItem("lang") || "en";
    $translateProvider
        .useStaticFilesLoader({
        prefix: `${baseURL}assets/js/translations/`,
        suffix: ".json",
        })
        .preferredLanguage(lang)
        .useSanitizeValueStrategy(null)
        .useMissingTranslationHandlerLog();
    });
        