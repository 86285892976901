module.exports = EventDatesController;

EventDatesController.$inject = [
  "$stateParams",
  "HostsService",
  "PaginationService",
  "$sce",
  "$uibModal",
  "$http",
  "$scope",
  "toaster",
  "stripe",
  "StorageService",
];

function EventDatesController(
  $stateParams,
  HostsService,
  PaginationService,
  $sce,
  $uibModal,
  $http,
  $scope,
  toaster,
  stripe,
  StorageService
) {
  //Variables
  const vm = this;
  vm.eventsList = [];
  vm.isCollapsed = true;
  vm.limit = "1000";
  vm.filterDate = "all";
  vm.eventDetails = false;
  vm.currentEvent = null;
  vm.allowCancel = true;
  vm.allowFinish = false;
  vm.today = moment().format("YYYY-MM-DD");
  let currentEventDate = {};
  vm.isLoading = false;
  vm.pager = {};

  //Methods
  vm.seeDetals = seeDetals;
  vm.renderHtml = renderHtml;
  vm.hideDetails = hideDetails;
  vm.finishEvent = finishEvent;
  vm.cancelEvent = cancelEvent;
  vm.showCancelGuest = showCancelGuest;
  vm.showAcceptGuest = showAcceptGuest;
  vm.showRejectGuest = showRejectGuest;
  vm.showAddFeedback = showAddFeedback;
  vm.setPage = setPage;

  function init() {
    HostsService.getHostEventsDates().then(_success, _error);

    /**
     * @name _success
     * @param {Object} response
     * @returns {undefined}
     */
    function _success(response) {
      vm.eventsList = response.data.dates.length ? response.data.dates : [];

      for (const event of vm.eventsList) {
        let sumMoney = 0;
        let sumGuests = 0;

        if (event.details.length) {
          for (const detail of event.details) {
            sumMoney += Number.parseInt(detail.amount, 10);
            sumGuests += Number.parseInt(detail.guests, 10);
          }
          event.raised =
            sumGuests > 0 ? sumGuests * Number.parseInt(event.price, 10) : 0;
          event.guests = sumGuests;
        }

        event.show_date = moment(event.event_date, "YYYY-MM-DD").format(
          "DD-MM-YYYY"
        );
      }

      setPage(1);
    }

    /**
     * @name _error
     * @param {Object} err
     * @returns {undefined}
     */
    function _error(err) {
      console.error(err);
    }

    if (
      $stateParams.id &&
      $stateParams.date &&
      $stateParams.token &&
      $stateParams.action
    ) {
      const data = {
        id: $stateParams.id,
        event_date: $stateParams.date,
      };
      seeDetals(data);
    }
  }

  function setPage(page) {
    if (page < 1 || page > vm.pager.totalPages) {
      return;
    }
    // get pager object from service
    vm.pager = PaginationService.getPager(vm.eventsList.length, page);
    // get current page of items
    vm.items = vm.eventsList.slice(vm.pager.startIndex, vm.pager.endIndex + 1);
  }

  function showCancelGuest(guest) {
    $scope.title = "Cancel Reservation";
    $scope.modal_message =
      "Do you want to cancel the Food Experience reservation for this client? The client will be 100% reimbursed, please click to Confirm if you would like to proceed.";

    const modalInstance = $uibModal.open({
      animation: true,
      templateUrl: "assets/js/app/modal/confirmation-modal.html",
      controller: "ModalInstanceCtrl",
      scope: $scope,
      size: 1,
      resolve: {
        data: () => $scope.data,
      },
    });

    modalInstance.result
      .then((data) => {
        vm.isLoading = true;
        HostsService.cancelGuestReservation(
          guest.reservation_token,
          data.message
        )
          .then((response) => {
            if (response.data.response === "success") {
              guest.reservation_status = "canceled";
              toaster.pop(
                "success",
                "Reservation canceled",
                "The reservation was canceled successfully.",
                4000
              );
            } else {
              toaster.pop(
                "warning",
                "Error",
                "Something went wrong, please try again.",
                4000
              );
            }
            vm.isLoading = false;
          })
          .catch((err) => {
            vm.isLoading = false;
            toaster.pop(
              "error",
              "Error",
              "Something went wrong, please try again.",
              4000
            );
          });
      })
      .catch((res) => {
        vm.isLoading = false;
        modalInstance.close();
      });
  }

  function showAcceptGuest(guest) {
    $scope.title = "Approve Reservation";
    $scope.modal_message =
      "Do you want to approve this reservation request? Please click Confirm to proceed.";

    const modalInstance = $uibModal.open({
      animation: true,
      templateUrl: "assets/js/app/modal/confirmation-modal.html",
      controller: "ModalInstanceCtrl",
      scope: $scope,
      backdrop: "static",
      size: 1,
      resolve: {
        data: () => $scope.data,
      },
    });

    modalInstance.result
      .then((data) => {
        vm.isLoading = true;
        HostsService.approveGuestReservation(
          guest.reservation_token,
          data.message
        )
          .then((response) => {
            if (response.data.response === "success") {
              guest.reservation_status = "approved";
              toaster.pop(
                "success",
                "Reservation approved",
                "The reservation was approved successfully.",
                4000
              );
            } else {
              toaster.pop(
                "warning",
                "Error",
                "Something went wrong, please try again.",
                4000
              );
            }
            vm.isLoading = false;
          })
          .catch((err) => {
            vm.isLoading = false;
            toaster.pop(
              "error",
              "Error",
              "Something went wrong, please try again.",
              4000
            );
          });
      })
      .catch(() => {
        vm.isLoading = false;
        modalInstance.close();
      });
  }

  function showRejectGuest(guest) {
    $scope.title = "Reject Reservation";
    $scope.modal_message =
      "Do you want to reject this reservation request? Please click Confirm to proceed.";

    const modalInstance = $uibModal.open({
      animation: true,
      templateUrl: "assets/js/app/modal/confirmation-modal.html",
      controller: "ModalInstanceCtrl",
      scope: $scope,
      backdrop: "static",
      size: 1,
      resolve: {
        data: () => $scope.data,
      },
    });

    modalInstance.result
      .then((data) => {
        vm.isLoading = true;
        HostsService.rejectGuestReservation(
          guest.reservation_token,
          data.message
        )
          .then((response) => {
            if (response.data.response === "success") {
              guest.reservation_status = "canceled";
              toaster.pop(
                "success",
                "Reservation rejected",
                "The reservation was rejected successfully.",
                4000
              );
            } else {
              toaster.pop(
                "warning",
                "Error",
                "Something went wrong, please try again.",
                4000
              );
            }
            vm.isLoading = false;
          })
          .catch((err) => {
            vm.isLoading = false;
            toaster.pop(
              "error",
              "Error",
              "Something went wrong, please try again.",
              4000
            );
          });
      })
      .catch(() => {
        vm.isLoading = false;
        modalInstance.close();
      });
  }

  function showAddFeedback(guest) {
    $scope.stars = 5;

    const modalInstance = $uibModal.open({
      animation: true,
      templateUrl: "assets/js/app/modal/rate-host.html",
      controller: "ratingCtrl",
      scope: $scope,
      size: 1,
      resolve: {
        data: () => $scope.data,
      },
    });

    modalInstance.result
      .then((data) => {
        vm.isLoading = true;
        Object.assign(data, {
          event_id: currentEventDate.id,
          review_to: guest.id_user,
          reservation_token: guest.reservation_token,
          date: currentEventDate.date,
        });

        HostsService.addRate(data)
          .then((response) => {
            if (response.data.response === "success") {
              guest.allow_review = 1;
              toaster.pop(
                "success",
                "Thanks for rating this guest",
                "You have rated this guest.",
                4000
              );
            } else {
              toaster.pop(
                "warning",
                "Error",
                "Something went wrong, please try again.",
                4000
              );
            }
            vm.isLoading = false;
          })
          .catch((err) => {
            vm.isLoading = false;
            toaster.pop(
              "error",
              "Error",
              "Something went wrong, please try again.",
              4000
            );
          });
      })
      .catch(() => {
        vm.isLoading = false;
        modalInstance.close();
      });
  }

  /**
   * @name seeDetals
   * @param {Object} data
   * @returns {undefined}
   */
  function seeDetals(data) {
    vm.eventDetails = true;
    vm.selectedDate = moment(data.event_date).format("LL");
    const isSame = moment(data.event_date).isSame(vm.today);
    const isAfter = moment(vm.today).isAfter(data.event_date);
    currentEventDate = {
      id: data.id,
      date: data.event_date,
    };

    HostsService.getHostbyIdAndDate(data.id, data.event_date).then(
      _success,
      _error
    );

    /**
     * @name _success
     * @param {Object} response
     * @returns {undefined}
     */
    function _success(response) {
      vm.currentEvent = response.data.hosts[0];
      vm.allowFinish =
        vm.currentEvent.date_status === "open" && (isSame || isAfter);
      vm.allowCancel = vm.currentEvent.date_status === "open";

      if ($stateParams.token && $stateParams.action) {
        for (const guest of vm.currentEvent.guests) {
          if (
            guest.reservation_token === $stateParams.token &&
            guest.reservation_status === "pending"
          ) {
            if ($stateParams.action === "approve") {
              showAcceptGuest(guest);
            } else if ($stateParams.action === "reject") {
              showRejectGuest(guest);
            }
          }
        }
      }
    }

    /**
     * @name _error
     * @param {Object} err
     * @returns {undefined}
     */
    function _error(err) {
      console.error(err);
    }
  }

  function hideDetails() {
    vm.eventDetails = false;
  }

  function renderHtml(html_code) {
    return $sce.trustAsHtml(html_code);
  }

  /**
   * @name finishEvent
   * @description finishes the event
   */
  function finishEvent() {
    $scope.title = "Do you want to finish the event?";
    $scope.modal_message =
      "Do you want to mark this event as finished? Please click Confirm to proceed.";

    const modalInstance = $uibModal.open({
      animation: true,
      templateUrl: "assets/js/app/modal/confirmation-modal.html",
      controller: "ModalInstanceCtrl",
      scope: $scope,
      size: 1,
      resolve: {
        data: () => $scope.data,
      },
    });

    modalInstance.result
      .then(() => {
        vm.isLoading = true;
        const formatedDate = moment(currentEventDate.date).format("LL");
        HostsService.updateEvent(
          currentEventDate.id,
          currentEventDate.date,
          formatedDate,
          "finished"
        )
          .then((response) => {
            if (response.data.response === "success") {
              vm.allowFinish = false;
              vm.allowCancel = false;
              vm.currentEvent.date_status = "finished";
              toaster.pop(
                "success",
                "Event finished",
                "All your guests for this event were notified.",
                4000
              );
              init();
            } else {
              toaster.pop(
                "warning",
                "Error finishing the event",
                "There was an error updating the event, please try again.",
                4000
              );
            }
            vm.isLoading = false;
          })
          .catch((err) => {
            vm.isLoading = false;
            toaster.pop(
              "error",
              "Error",
              "Something went wrong, please try again.",
              4000
            );
          });
      })
      .catch(() => {
        vm.isLoading = false;
        modalInstance.close();
      });
  }

  function cancelEvent() {
    const freeEvent = vm.currentEvent.free === "1";
    if (freeEvent) {
      $scope.title = "Do you want to cancel the event?";
      const modalInstance = $uibModal.open({
        animation: true,
        templateUrl: "assets/js/app/modal/confirmation-modal.html",
        controller: "ModalInstanceCtrl",
        scope: $scope,
        size: 1,
        resolve: {
          data: () => $scope.data,
        },
      });

      modalInstance.result
        .then(() => {
          vm.isLoading = true;
          const formatedDate = moment(currentEventDate.date).format("LL");
          HostsService.hostCancelFreeEvent(
            currentEventDate.id,
            currentEventDate.date,
            formatedDate,
            "canceled"
          )
            .then((response) => {
              if (response.data.response === "success") {
                vm.allowFinish = false;
                vm.allowCancel = false;
                vm.currentEvent.date_status = "canceled";
                toaster.pop(
                  "success",
                  "Event canceled",
                  "This event has been canceled.",
                  4000
                );
                init();
              } else {
                toaster.pop(
                  "warning",
                  "Error canceling the event",
                  "There was an error updating the event, please try again.",
                  4000
                );
              }
              vm.isLoading = false;
            })
            .catch((err) => {
              vm.isLoading = false;
              toaster.pop(
                "error",
                "Error",
                "Something went wrong, please try again.",
                4000
              );
            });
        })
        .catch(() => {
          vm.isLoading = false;
          modalInstance.close();
        });
    } else {
      if (vm.currentEvent.guests.length > 0) {
        const totalRaised =
          vm.currentEvent.total_reservations * vm.currentEvent.price;
        let guests = 0;
        for (const guest of vm.currentEvent.guests) {
          guests += parseInt(guest.guests_qty, 10);
        }
        const totalToPay = (vm.currentEvent.price * 0.2 * guests).toFixed(2);
        $scope.title = `In order to cancel the event, you have to pay 2% from $${totalRaised} (raised money), then you must pay $${totalToPay} to cancel this event.`;
        const modalInstance = $uibModal.open({
          animation: true,
          templateUrl: "assets/js/app/modal/pay-to-foodiiz-modal.html",
          controller: "ModalInstanceCtrl",
          scope: $scope,
          size: 1,
          resolve: {
            data: () => $scope.data,
          },
        });

        modalInstance.result
          .then((data) => {
            const formatedDate = moment(currentEventDate.date).format("LL");
            const card = {
              number: data.number,
              exp_month: data.exp_month,
              exp_year: data.exp_year,
              cvc: data.cvc,
            };
            stripe.card
              .createToken(card)
              .then((response) => {
                const token = StorageService.getItem("token") || "";
                const params = {
                  access_token: response.id,
                  amount: Math.round(totalToPay * 100),
                  last_four: response.card.last4,
                  detail: vm.currentEvent.title,
                  event_id: vm.currentEvent.id,
                  event_date: currentEventDate.date,
                  formatedDate: formatedDate,
                  token: token,
                };

                return $http({
                  headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                  },
                  url: `${baseURL}Stripe/Payment/eventCancelation/`,
                  method: "POST",
                  data: $.param(params),
                });
              })
              .then((response) => {
                if (response.data.response === "success") {
                  vm.allowFinish = false;
                  vm.allowCancel = false;
                  vm.currentEvent.date_status = "canceled";
                  toaster.pop(
                    "success",
                    "Event canceled",
                    "All your guests for this event were notified.",
                    4000
                  );
                }
              })
              .catch((err) => {
                if (err.type && /^Stripe/.test(err.type)) {
                  console.log("Stripe error: ", err.message);
                } else {
                  console.log(
                    "Other error occurred, possibly with your API",
                    err.message
                  );
                }
              });
          })
          .catch(() => {
            modalInstance.close();
          });
      } else {
        $scope.title = "Do you want to cancel the event?";
        const modalInstance = $uibModal.open({
          animation: true,
          templateUrl: "assets/js/app/modal/confirmation-modal.html",
          controller: "ModalInstanceCtrl",
          scope: $scope,
          size: 1,
          resolve: {
            data: () => $scope.data,
          },
        });

        modalInstance.result
          .then(() => {
            vm.isLoading = true;
            const formatedDate = moment(currentEventDate.date).format("LL");
            HostsService.updateEvent(
              currentEventDate.id,
              currentEventDate.date,
              formatedDate,
              "canceled"
            )
              .then((response) => {
                if (response.data.response === "success") {
                  vm.allowFinish = false;
                  vm.allowCancel = false;
                  vm.currentEvent.date_status = "canceled";
                  toaster.pop(
                    "success",
                    "Event canceled",
                    "This event has been canceled.",
                    4000
                  );
                  init();
                } else {
                  toaster.pop(
                    "warning",
                    "Error canceling the event",
                    "There was an error updating the event, please try again.",
                    4000
                  );
                }
                vm.isLoading = false;
              })
              .catch((err) => {
                vm.isLoading = false;
                toaster.pop(
                  "error",
                  "Error",
                  "Something went wrong, please try again.",
                  4000
                );
              });
          })
          .catch(() => {
            vm.isLoading = false;
            modalInstance.close();
          });
      }
    }
  }

  init();
}
