module.exports = TranslationService;

TranslationService.$inject = ['$http', '$q'];

function TranslationService($http, $q) {

    return (options) => {

        if (!options || (!angular.isArray(options.files) && (!angular.isString(options.prefix) || !angular.isString(options.suffix)))) {
            throw new Error('Couldn\'t load static files, no files and prefix or suffix specified!');
        }

        if (!options.files) {
            options.files = [{
                    prefix: options.prefix,
                    suffix: options.suffix
                }];
        }

        const load = (file) => {
            if (!file || (!angular.isString(file.prefix) || !angular.isString(file.suffix))) {
                throw new Error('Couldn\'t load static file, no prefix or suffix specified!');
            }
            const deferred = $q.defer();
            $http(angular.extend({
                url: [
                    file.prefix,
                    options.key,
                    file.suffix
                ].join(''),
                method: 'GET',
                params: ''
            }, options.$http)).then((data) => {
                deferred.resolve({data: data});
            }, (msg, code) => {
                deferred.reject(msg);
                $log.error(msg, code);
            });

            return deferred.promise;

        };

        const deferred = $q.defer();
        const promises = [];
        const length = options.files.length;

        for (let i = 0; i < length; i++) {
            promises.push(load({
                prefix: options.files[i].prefix,
                key: options.key,
                suffix: options.files[i].suffix
            }));
        }

        $q.all(promises).then((data) => {
            const modifiedData = [data[0]];
            const length = modifiedData.length;
            const mergedData = {};

            for (let i = 0; i < length; i++) {
                for (const key in data[i]) {
                    mergedData[key] = data[i][key];
                }
            }

            deferred.resolve(mergedData);
        }, (data) => {
            deferred.reject(data);
        });

        return deferred.promise;
    };

}