module.exports = UsersService;

UsersService.$inject = [
  "$rootScope",
  "$http",
  "$log",
  "$q",
  "StorageService",
  "ApiRequest",
];

function UsersService($rootScope, $http, $log, $q, StorageService, ApiRequest) {
  $rootScope.currentUser = null;

  _.extend(this, {
    signUp: signUp,
    isVerified: isVerified,
    signUpFacebook: signUpFacebook,
    signUpGoogle: signUpGoogle,
    login: login,
    sendResetToken: sendResetToken,
    getUserInfo: getUserInfo,
    saveUser: saveUser,
    confirmEmail: confirmEmail,
    validateResetToken: validateResetToken,
    resetPassword: resetPassword,
    saveImage: saveImage,
    getUserInfobyId: getUserInfobyId,
    showUserbyId: showUserbyId,
    getCountriesPayout: getCountriesPayout,
    saveStripeAccount: saveStripeAccount,
    savePayPalAccount: savePayPalAccount,
    saveBankAccount: saveBankAccount,
    updateCurrency: updateCurrency,
    updateLanguage: updateLanguage,
    updatePassword: updatePassword,
    getStripePermissions: getStripePermissions,
    becomePremium: becomePremium,
    deleteUser: deleteUser,
    newsletterSignup: newsletterSignup,
  });

  /**
   * @name signUp
   * @description attempts to register the user
   * @param {Object} user
   */
  // users.js
  function signUp(user) {
    const newsLetter = user.newsLetter ? 1 : 0;
    const organizeFoodEvent = user.organizeFoodEvent ? 1 : 0;
    const premium = user.premium ? 1 : 0;
    const params = {
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      password: user.password,
      news: newsLetter,
      organize: organizeFoodEvent,
      premium: premium,
      lang: user.lang, //nabi new
    };

    return ApiRequest.callApi("POST", "Users/register/", params);
  }

  /**
   * @name googleSingup
   * @description registers a new user
   * @param user
   * @returns {*}
   */
  function signUpGoogle(user) {
    const params = {
      first_name: user.ofa,
      last_name: user.wea,
      email: user.U3,
      cover_url: user.Paa,
    };

    return ApiRequest.callApi("POST", "Users/registerGoogle/", params);
  }

  /**
   * @name signUpFacebook
   * @description registers a new user
   * @param {type} user
   * @returns {.$q@call;defer.promise}
   */
  function signUpFacebook(user) {
    const params = {
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      gender: user.gender,
      cover_url: user.cover_url,
    };

    return ApiRequest.callApi("POST", "Users/registerFacebook/", params);
  }

  /**
   * @name isVerified
   * @description checks if the email exists
   * @param user
   * @returns {*}
   */
  function isVerified(user) {
    const email = user.email || user.U3;
    const params = {
      email: email,
      password: user.password,
    };

    return ApiRequest.callApi("POST", "Users/isVerified/", params);
  }

  /**
   * @name login
   * @description attempts to login the user
   * @param {Object} user
   */
  function login(user) {
    const params = {
      email: user.email,
      password: user.password,
    };

    return ApiRequest.callApi("POST", "Users/login/", params);
  }

  /**
   * @name getUserInfo
   * @description Gets the user info
   * @returns {Promise} A promise that resolves with the user info or rejects with an error
   */
  async function getUserInfo() {
    const params = {};
    return ApiRequest.callApi("POST", "Users/getUserInfo/", params);
  }

  /**
   * @name getUserInfobyId
   * @description gets the user info by id
   * @param {string} id - The user ID
   * @returns {Promise} A promise that resolves with the user info or rejects with an error
   */
  function getUserInfobyId(id) {
    const params = {
      id: id,
    };
    return ApiRequest.callApi("POST", "Users/getUserInfoId/", params);
  }

  /**
   * @name showUserbyId
   * @description gets the user info by id
   * @param {string} id - The user ID
   * @returns {Promise} A promise that resolves with the user info or rejects with an error
   */
  function showUserbyId(id) {
    const params = { id: id };

    return ApiRequest.callApi("POST", "Users/showUserById/", params);
  }

  /**
   * @name saveuser
   * @description Saves the user
   * @param user
   * @returns {deferred.promise|{then, catch, finally}}
   */
  function saveUser(user) {
    const params = {
      first_name: user.first_name,
      last_name: user.last_name,
      username: user.username,
      address: user.address,
      birthdate: moment(user.birthdate, "DD/MM/YYYY").format("YYYY-MM-DD"),
      currency: user.currency,
      description: user.description,
      genre: user.genre,
      language: user.language,
      phone_number: user.phone_number,
      country_code: user.country_code,
      preferences: user.preferences,
      school: user.school,
      time_zone: user.time_zone,
      work: user.work,
      languages: user.languages,
      news: user.news,
    };

    return ApiRequest.callApi("POST", "Users/save/", params);
  }

  function confirmEmail(token) {
    const params = { token: token };
    return ApiRequest.callApi("POST", "Users/confirmEmail/", params);
  }

  function validateResetToken(token) {
    const params = {
      token: token,
    };
    return ApiRequest.callApi("POST", "Users/validateResetToken/", params);
  }

  function saveImage(image) {
    const deferred = $q.defer();
    let token = StorageService.getItem("token");
    if (!token) token = "";
    $http({
      method: "POST",
      url: `${baseURL}Users/saveImage/${token}`,
      secureuri: false,
      headers: { "Content-Type": "multipart/form-data" },
      dataType: "json",
      data: {
        file: image,
      },
      transformRequest: (data, headersGetter) => {
        const formData = new FormData();
        angular.forEach(data, (value, key) => {
          formData.append(key, value);
        });
        const headers = headersGetter();
        headers["Content-Type"] = undefined;
        return formData;
      },
    }).then(
      (data) => {
        deferred.resolve({ data: data });
      },
      (msg, code) => {
        deferred.reject(msg);
        $log.error(msg, code);
      }
    );
    return deferred.promise;
  }

  function sendResetToken(email) {
    const params = {
      email: email,
    };

    return ApiRequest.callApi("POST", "Users/sendResetToken/", params);
  }

  function resetPassword(email, password) {
    const params = {
      email: email,
      password: password,
    };

    return ApiRequest.callApi("POST", "Users/resetPassword/", params);
  }

  function getCountriesPayout() {
    return ApiRequest.callApi("GET", "Stripe/Payment/getCountries", {});
  }

  function saveStripeAccount(account) {
    const params = {
      id: account.id,
      stripe_account: account.account_id,
    };

    return ApiRequest.callApi("POST", "Users/saveStripeAccount/", params);
  }

  function getStripePermissions(code) {
    const params = {
      code: code,
    };

    return ApiRequest.callApi(
      "POST",
      "Stripe/Payment/getStripePermissions/",
      params
    );
  }

  function becomePremium() {
    return ApiRequest.callApi("POST", "Users/becomePremium/", {});
  }

  function savePayPalAccount(account) {
    const params = {
      id: account.id,
      paypal_email: account.paypal_email,
    };

    return ApiRequest.callApi("POST", "Users/savePaypalAccount/", params);
  }

  function saveBankAccount(account) {
    const params = {
      id: account.id,
      currency: account.currency,
      username: account.user_name,
      bankname: account.bank_name,
      banknumber: account.bank_number,
      bankswift: account.bank_swift,
      extrainfo: account.extra_info,
    };

    return ApiRequest.callApi("POST", "Users/saveBankAccount/", params);
  }

  function updateCurrency(_currency) {
    const deferred = $q.defer();
    const user = JSON.parse(StorageService.getItem("globalUser"));
    user.currency = _currency;
    StorageService.setItem("globalUser", JSON.stringify(user));
    deferred.resolve({ response: "success" });

    return deferred.promise;
  }

  function updateLanguage(_language) {
    const params = {
      language: _language,
    };
    return ApiRequest.callApi("POST", "Users/updateLanguage/", params);
  }

  function updatePassword(password) {
    const params = {
      password: password,
    };
    return ApiRequest.callApi("POST", "Users/updatePassword/", params);
  }

  function deleteUser(user) {
    const params = {
      user_id: user.id,
    };
    return ApiRequest.callApi("POST", "Users/deleteUser/", params);
  }

  function newsletterSignup(email) {
    const params = {
      email: email,
    };
    return ApiRequest.callApi("POST", "Users/newsletterSignup/", params);
  }
}
