module.exports = HostsService;

HostsService.$inject = [
  "$rootScope",
  "$http",
  "$log",
  "$q",
  "StorageService",
  "ApiRequest",
];

function HostsService($rootScope, $http, $log, $q, StorageService, ApiRequest) {
  $rootScope.currentHosts = null;

  _.extend(this, {
    saveHosts: saveHosts,
    getHostUser: getHostUser,
    getGuestExperiences: getGuestExperiences,
    getHostIdUser: getHostIdUser,
    getHostsByLocation: getHostsByLocation,
    getHostsByFilter: getHostsByFilter,
    getCurrencyAmount: getCurrencyAmount,
    getCurrencyAmountByEventID: getCurrencyAmountByEventID,
    getHostbyId: getHostbyId,
    getHostbyIdAndDate: getHostbyIdAndDate,
    getInineraryByToken: getInineraryByToken,
    getHostEventsDates: getHostEventsDates,
    updateEvent: updateEvent,
    addRate: addRate,
    removeImage: removeImage,
    guestEventCancelation: guestEventCancelation,
    guestEventCancelationNoRefund: guestEventCancelationNoRefund,
    hostEventCancelation: hostEventCancelation,
    hostCancelFreeEvent: hostCancelFreeEvent,
    eventFreeReservation: eventFreeReservation,
    guestFreeEventCancelation: guestFreeEventCancelation,
    getPremiumHosts: getPremiumHosts,
    rejectGuestReservation: rejectGuestReservation,
    approveGuestReservation: approveGuestReservation,
    cancelGuestReservation: cancelGuestReservation,
    validateCoupon: validateCoupon,
  });

  function validateCoupon(code) {
    const params = {
      code,
    };

    return ApiRequest.callApi("POST", "Hosts/validateCoupon/", params);
  }

  function getCurrencyAmount(currencydata) {
    const params = {
      amount: currencydata.amount,
      fromcurrency: currencydata.fromcurrency,
      tocurrency: currencydata.tocurrency,
    };

    return ApiRequest.callApi("POST", "Hosts/getCurrencyAmount/", params);
  }

  function saveHosts(host) {
    const deferred = $q.defer();

    // Format selected dates
    for (const [i, newDate] of host.selectedDates.entries()) {
      host.selectedDates[i] = newDate.format("YYYY-MM-DD");
    }

    // Prepare parameters
    const params = {
      id: host.id,
      experiences: host.experiences,
      cuisines: host.cuisines,
      diets: host.diets,
      min_guests: host.min_guests,
      max_guests: host.max_guests,
      title: host.title,
      description: host.description,
      menu: host.menu,
      after_activity: host.after_activity,
      current_city: host.place,
      venue_type: host.venue_type,
      address: host.address,
      city_name: host.city_name,
      lng: host.lng,
      lat: host.lat,
      currency: host.currency,
      price: host.price,
      kids_price: host.kids_price,
      open_hour: host.openHour,
      close_hour: host.closeHour,
      accommodations: host.accommodations,
      drinks: host.drinks,
      status: host.status,
      dates: host.selectedDates,
      free: host.free ? 1 : 0,
      free_kids: host.free_kids ? 1 : 0,
      hide_guests: host.hide_guests ? 1 : 0,
      last_minute: host.last_minute ? 1 : 0,
    };

    return ApiRequest.callApi("POST", "Hosts/save/", params);
  }

  function removeImage(_path) {
    const params = {
      path: _path,
    };

    return ApiRequest.callApi("POST", "Hosts/removeEventImage/", params);
  }

  function getHostUser() {
    const params = {};
    return ApiRequest.callApi("POST", "Hosts/getHostUser/", params);
  }

  function getGuestExperiences() {
    const params = {};
    return ApiRequest.callApi("POST", "Hosts/getGuestExperiences/", params);
  }

  function getHostIdUser(id) {
    const params = {
      id: id,
    };

    return ApiRequest.callApi("POST", "Hosts/getHostbyUserId/", params);
  }

  function getHostbyId(id) {
    const params = {
      id: id,
    };

    return ApiRequest.callApi("POST", "Hosts/getHostbyId/", params);
  }

  function getHostbyIdAndDate(id, date, currency) {
    const params = {
      id: id,
      date: date,
      currency: currency,
    };

    return ApiRequest.callApi("POST", "Hosts/getHostbyIdAndDate/", params);
  }

  function getHostsByLocation(lat, lng, distance, date, location, currency) {
    const params = {
      lat: lat,
      lng: lng,
      distance: distance,
      date: date,
      location: location,
      currency: currency,
    };

    return ApiRequest.callApi("POST", "Hosts/getHostsByLocation/", params);
  }

  function getHostsByFilter(currency) {
    const params = {
      currency: currency,
    };

    return ApiRequest.callApi("POST", "Hosts/getHostsByFilter/", params);
  }

  function getInineraryByToken(token) {
    const params = {
      token: token,
    };

    return ApiRequest.callApi("POST", "Hosts/getInineraryByToken/", params);
  }

  function getPremiumHosts(currency) {
    const params = {
      currency: currency,
    };

    return ApiRequest.callApi("POST", "Hosts/getPremiumHosts/", params);
  }

  function getCurrencyAmountByEventID(currencydata) {
    const params = {
      id_event: currencydata.id_event,
      amount: currencydata.amount,
      fromcurrency: currencydata.fromcurrency,
      tocurrency: currencydata.tocurrency,
    };

    return ApiRequest.callApi(
      "POST",
      "Hosts/getCurrencyAmountByEventID/",
      params
    );
  }

  function rejectGuestReservation(token, message) {
    const params = {
      token,
      message,
    };

    return ApiRequest.callApi(
      "POST",
      "Stripe/Payment/rejectGuestReservation/",
      params
    );
  }

  function approveGuestReservation(token, message) {
    const params = {
      token,
      message,
    };

    return ApiRequest.callApi(
      "POST",
      "Stripe/Payment/approveGuestReservation/",
      params
    );
  }

  function getHostEventsDates() {
    const params = {};
    return ApiRequest.callApi("POST", "Hosts/getEventsDates/", params);
  }

  function updateEvent(id, date, formatedDate, status) {
    const params = {
      id,
      date,
      formateddate: formatedDate,
      status,
    };

    return ApiRequest.callApi("POST", "Hosts/updateEvent/", params);
  }

  function hostCancelFreeEvent(id, date, formatedDate, status) {
    const params = {
      id,
      date,
      formateddate: formatedDate,
      status,
    };

    return ApiRequest.callApi("POST", "Hosts/cancelFreeEvent/", params);
  }

  function addRate(review) {
    const params = {
      event_id: review.event_id,
      stars: review.stars,
      comment: review.review,
      review_to: review.review_to,
      reservation_token: review.reservation_token,
      date: review.date,
    };

    return ApiRequest.callApi("POST", "Hosts/addRate/", params);
  }

  function guestEventCancelation(token, message) {
    const params = {
      token,
      message,
    };

    return ApiRequest.callApi(
      "POST",
      "Stripe/Payment/guestEventCancelation/",
      params
    );
  }

  function cancelGuestReservation(token, message) {
    const params = {
      token,
      message,
    };

    return ApiRequest.callApi(
      "POST",
      "Stripe/Payment/cancelGuestReservation/",
      params
    );
  }

  function hostEventCancelation(token) {
    const params = {
      token,
    };

    return ApiRequest.callApi(
      "POST",
      "Stripe/Payment/hostEventCancelation/",
      params
    );
  }

  function eventFreeReservation(event) {
    const params = {
      amount: "0",
      last_four: "0000",
      title: event.details.title,
      first_name: event.guest.first_name,
      last_name: event.guest.last_name,
      address: event.guest.address,
      id_host: event.details.id_user,
      id_guest: event.guest.id,
      guests_qty: event.guests_qty,
      kids_qty: event.kids_qty,
      event_date: event.event_date,
      event_id: event.details.id,
      host_name: event.details.first_name,
      host_email: event.details.email,
      formated_date: event.formated_date,
      price_person: event.details.price,
      guest_email: event.guest.email,
      currency: event.currency,
      original_currency: event.details.currency,
      original_price: "0",
      original_final_price: "0",
      notes: event.notes,
      phone: event.phone,
      guestemail: event.guestemail,
      guestname: event.guestname,
    };

    return ApiRequest.callApi("POST", "Hosts/eventFreeReservation/", params);
  }

  function guestEventCancelationNoRefund(token, message) {
    const params = {
      token,
      message,
    };

    return ApiRequest.callApi(
      "POST",
      "Stripe/Payment/guestEventCancelationNoRefund/",
      params
    );
  }

  function guestFreeEventCancelation(token) {
    const params = {
      token,
    };

    return ApiRequest.callApi(
      "POST",
      "Stripe/Payment/guestFreeEventCancelation/",
      params
    );
  }
}
