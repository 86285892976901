'use strict';

module.exports = ContactController;

ContactController.$inject = ['toaster', 'ContactService'];

function ContactController(toaster, ContactService) {
    var vm = this;
    vm.formData = null;
    vm.sending = false;
    vm.send = send;
    vm.init = init;

    /**
     * @name init
     * @description to set the title and description
     */
    function init() {
        //ngMeta.setTitle('Willing to Cook local food for people? Contact Us Online & Earn Money');
        //ngMeta.setTag('description', 'We love emails almost as much as we love food. So, what are you waiting for? Be a host in your city or region and get an opportunity to meet new locals, tourist over a shared meal experience.');
    }

    /**
     * @name send
     * @description send information
     */
    function send() {
        vm.sending = true;
        ContactService.sendEmail(vm.formData).then(
                function (response) {
                    if (response.data.response === 'success') {
                        toaster.pop("success", "Message sending", "Your message was sent.", 3000);
                        setTimeout(function () {
                            location.reload();
                        }, 1000);
                    }

                },
                function (err) {
                    toaster.pop('error', "Error", "Something went wrong, please try again.", 4000);
                }
        );
    }

    init();
}